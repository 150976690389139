import React, { FC } from 'react';

import { AddonDto, useAddonsQuery } from '@hofy/api-admin';
import {
    addonTargetColors,
    addonTypeColors,
    useTrAddonItem,
    useTrAddonTarget,
    useTrAddonType,
} from '@hofy/api-shared';
import { Badge, BaseTable, BooleanBadge, Box, ErrorStatePlaceholder } from '@hofy/ui';

interface AddonsTableProps {
    onUpdate(addon: AddonDto): void;
}

export const AddonsTable: FC<AddonsTableProps> = ({ onUpdate }) => {
    const { data: addons, isLoading, isError } = useAddonsQuery();
    const trAddonType = useTrAddonType();
    const trAddonTarget = useTrAddonTarget();
    const trAddonItem = useTrAddonItem();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <BaseTable
            data={addons}
            toKey={addon => addon.id}
            onRowClick={onUpdate}
            isLoading={isLoading}
            columns={[
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 0,
                    width: 140,
                    renderer: addon => addon.name,
                },
                {
                    id: 'publicName',
                    header: 'Public name',
                    flexGrow: 0,
                    width: 140,
                    renderer: addon => addon.publicName,
                },
                {
                    id: 'public',
                    header: 'Public',
                    flexGrow: 0,
                    width: 60,
                    renderer: addon => <BooleanBadge value={addon.isPublic} />,
                },
                {
                    id: 'type',
                    header: 'Type',
                    flexGrow: 0,
                    width: 100,
                    renderer: addon => (
                        <Badge color={addonTypeColors[addon.type]} label={trAddonType(addon.type)} />
                    ),
                },
                {
                    id: 'target',
                    header: 'Linked to',
                    flexGrow: 0,
                    width: 120,
                    renderer: addon => (
                        <Badge color={addonTargetColors[addon.target]} label={trAddonTarget(addon.target)} />
                    ),
                },
                {
                    id: 'services',
                    header: 'Services',
                    flexGrow: 1,
                    renderer: addon => (
                        <Box row wrap gap={10}>
                            {addon.services.map(item => (
                                <Badge key={item} label={trAddonItem(item)} />
                            ))}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
