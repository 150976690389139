import { getEnumValues } from '@hofy/global';

export enum SplitterType {
    Currency = 'currency',
    DomesticVat = 'domestic_vat',
    ByInvoiceEntryType = 'by_invoice_entry_type',
    User = 'user',
    Team = 'team',
    Contract = 'contract',
    UserCountry = 'user_country',
}

export const canCreateMultiple = (splitterType: SplitterType) => {
    return splitterType === SplitterType.ByInvoiceEntryType || splitterType === SplitterType.UserCountry;
};

export const allowedSplitterTypes = [
    SplitterType.ByInvoiceEntryType,
    SplitterType.User,
    SplitterType.Team,
    SplitterType.Contract,
    SplitterType.UserCountry,
];

export const allSplitterTypes = getEnumValues<SplitterType>(SplitterType);
