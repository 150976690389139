export enum EmailTopic {
    TestMessage = 'test_message',
    InviteManager = 'invite_manager',
    InviteMember = 'invite_member',
    PasswordReset = 'password_reset',
    RoleChange = 'role_change',
    EmailConfirm = 'email_confirm',
    OrderConfirm = 'order_confirm',
    OrderRequest = 'order_request',
    OrderApproved = 'order_approved',
    OrderSending = 'order_sending',
    PrepaidOrderCreated = 'prepaid_order_created',
    OrderBooked = 'order_booked',
    OrderCollected = 'order_collected',
    OrderDelivering = 'order_delivering',
    OrderDelivered = 'order_delivered',
    OrderBackorder = 'order_backorder',
    IssueCreated = 'issue_created',

    CollectionRequest = 'collection_request',
    CollectionRequestPackagingTrackingLink = 'collection_request_packaging_tracking_link',

    DeviceCollection = 'device_collection',

    RequestOrganizationContract = 'request_organization_contract',
    AddSeatsRequest = 'add_seats_request',
    EnableFeatureRequest = 'enable_features_request',

    StoreAndReuseDeliveryBooked = 'store_and_reuse_delivery_booked',
    StoreAndReuseCollectionCollected = 'store_and_reuse_collection_collected',
    StoreAndReuseContractAvailable = 'store_and_reuse_contract_available',
    PartnerStoreAndReuseDeliveryBooked = 'partner_store_and_reuse_delivery_booked',
    PartnerStoreAndReuseCollectionCollected = 'partner_store_and_reuse_collection_collected',
    PartnerStoreAndReuseContractAvailable = 'partner_store_and_reuse_contract_available',

    ContractAlmostExpiring = 'contract_almost_expiring',
    AssetClearanceRequested = 'asset_clearance_requested',
    ManageRefreshedDevice = 'manage_refreshed_device',
    HrIntegrationIssueReceived = 'hr_integration_issue_received',
    NewHireOrderReminder = 'new_hire_order_reminder',

    PrepaidOrderPaymentReminder = 'prepaid_order_payment_reminder',

    PaymentReceipt = 'payment_receipt',
    TwoFirstPlan = 'two_first_plan',

    InvoiceCreated = 'invoice_created',
    CreditNoteCreated = 'credit_note_created',
    PaymentReminder = 'payment_reminder',
    DunningZero = 'dunning_zero',
    DunningOne = 'dunning_one',
    PaymentFailed = 'payment_failed',
}
