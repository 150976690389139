import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ItemGrade } from '../types/ItemGrade';

export const useTrAssetGrade = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ItemGrade>(ItemGrade, 'asset-grade');
};

export const useTrAssetGradeLabel = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ItemGrade>(ItemGrade, 'asset-grade-label');
};

export const useTrAssetGradeWithCondition = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ItemGrade>(ItemGrade, 'asset-grade-with-condition');
};
