import React, { FC, useState } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Alert,
    AsyncButton,
    Box,
    Button,
    LabeledCheckbox,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpgradeToPerSeatOrganization } from '../../../store/organizations/useUpgradeToPerSeatOrganization';

interface UpgradeToPerSeatModalProps {
    organizationId: UUID;
    onClose(): void;
}

export const UpgradeToPerSeatModal: FC<UpgradeToPerSeatModalProps> = ({ organizationId, onClose }) => {
    const [confirmed, setConfirmed] = useState(false);
    const { upgradeToPerSeat, isLoading } = useUpgradeToPerSeatOrganization(organizationId, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={650}>
            <ModalHeader title={tr('organization-page.per-seat-upgrade-modal.title')} />
            <ModalContent>
                <Box column gap={20} alignItems='flex-start'>
                    <Paragraph3>{tr('organization-page.per-seat-upgrade-modal.description')}</Paragraph3>
                    <Alert
                        title={tr('organization-page.per-seat-upgrade-modal.alert.title')}
                        description={tr('organization-page.per-seat-upgrade-modal.alert.description')}
                        type='warning'
                    />
                    <LabeledCheckbox
                        label={
                            <Paragraph3 bold color={Color.ContentPrimary}>
                                {tr('organization-page.per-seat-upgrade-modal.confirmation')}
                            </Paragraph3>
                        }
                        checked={confirmed}
                        onChange={setConfirmed}
                    />
                </Box>
            </ModalContent>
            <ModalFooter>
                <Box flex={1}>
                    <Button
                        type='secondary'
                        label={tr('organization-page.per-seat-upgrade-modal.buttons.cancel')}
                        leftIcon={SvgIcon.Cross}
                        onClick={onClose}
                    />
                </Box>
                <AsyncButton
                    label={tr('organization-page.per-seat-upgrade-modal.buttons.submit')}
                    disabled={!confirmed}
                    onClick={upgradeToPerSeat}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
