import { useQuery } from '@tanstack/react-query';

import { issueService } from '@hofy/api-admin';

import { downloadIssueDocumentCacheKey } from './downloadIssueDocumentCacheKey';

export const useDownloadImageDocument = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [downloadIssueDocumentCacheKey, 'issueId', 'fileId'],
        queryFn: () => issueService.getIssueDocumentBlob,
    });

    return {
        download: data,
        isDownloading: isLoading,
        isDownloadError: isError,
    };
};
