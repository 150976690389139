import { ItemDto } from '@hofy/api-admin';
import { UUID, zeroUuid } from '@hofy/global';

export interface AssignBulkStorageOrderItemFormData {
    bulkStorageOrderItemId: UUID;
    items: AssignedBulkStorageItemFormData[];
}

export interface AssignBulkStorageOrderItemValidData {
    bulkStorageOrderItemId: UUID;
    items: AssignBulkStorageOrderItemValidFormData[];
}

export interface AssignedBulkStorageItemFormData {
    itemCode: string | null;
    itemDto: ItemDto | null;
}

export interface AssignBulkStorageOrderItemValidFormData {
    itemCode: string;
    itemDto: ItemDto;
}

export const emptyAssignBulkStorageOrderItemDetail: AssignedBulkStorageItemFormData = {
    itemCode: null,
    itemDto: null,
};

export const emptyAssignBulkStorageOrderItem = (): AssignBulkStorageOrderItemFormData => {
    return {
        bulkStorageOrderItemId: zeroUuid,
        items: [],
    };
};
