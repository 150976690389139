export enum OrderError {
    RequestStatus = 'current_request_status_is_invalid',
    CreditLimitExceeded = 'credit_limit_exceeded',
    NoneApprovedRequestItems = 'there_are_no_approved_request_items',
    SuspendedOrganization = 'organization_is_suspended',
    TrialOrganization = 'organization_status_is_trial',
    InvalidProductAvailability = 'invalid_product_availability',
    BasketPriceExceedAvailableCredit = 'basket_price_exceeds_available_credit',
    Country = 'residence_country_and_delivery_country_do_not_match',
    UserIsOffboarded = 'user_is_offboarded',
    TeamMemberEquipLimitReached = 'team_member_equip_limit_reached',
    AcquisitionTypeNotAllowed = 'acquisition_type_not_allowed',
    RentalTermNotAllowed = 'rental_term_not_allowed',
    MismatchBasketPayloadCountryUserCountry = 'basket_paylod_country_not_equal_to_user_country',
    NoAddress = 'empty_address',
    ExpressDeliveryUnsupportedCountry = 'express_delivery_unsupported_country',
    MissingVariant = 'missing_variant',
    NoItemsAvailableForReuse = 'no_items_available_for_reuse',
    UserHasNoActiveSeat = 'user_has_no_active_seat',
}
