import React, { FC, ReactNode, useState } from 'react';

import { isProd } from '@hofy/config';
import { Accordion, Box } from '@hofy/ui';

import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { ExportSection } from './sections/ExportSection';
import { ImportSection } from './sections/ImportSection';
import { SeedDemoOrganization } from './sections/SeedDemoOrganization';
import { SeedOrganization } from './sections/SeedOrganization';
import { SeedRandomHofyAssets } from './sections/SeedRandomHofyAssets';
import { SeedRandomNonHofyAssets } from './sections/SeedRandomNonHofyAssets';
import { SeedRandomOrders } from './sections/SeedRandomOrders';
import { SeedRandomOrganizationWarehouses } from './sections/SeedRandomOrganizationWarehouses';
import { SeedRandomUsers } from './sections/SeedRandomUsers';
import { SeedRandomWarehouseItems } from './sections/SeedWarehouseItems';

interface ExportImportPageProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

enum SelectedSection {
    Import = 1,
    Export,
    SeedUsers,
    SeedOrganization,
    SeedRequests,
    SeedItems,
    SeedDemoOrganization,
    SeedRentalContracts,
    SeedNonHofyAssets,
    SeedOrganizationWarehouses,
}

export const DataPage: FC<ExportImportPageProps> = ({ tab, tabs, onChangeTab }) => {
    const [section, setSection] = useState<SelectedSection>();

    const handleSetSection = (v: SelectedSection) => {
        if (section === v) {
            setSection(undefined);
        } else {
            setSection(v);
        }
    };
    return (
        <Box column flex='auto'>
            <SettingsHeader tabs={tabs} tab={tab} onChangeTab={onChangeTab} />
            <Box flex='auto' overflow='auto'>
                <Box column flex='auto' marginLeft={40} marginBottom={40} marginTop={30} gap={20} width={400}>
                    <Section
                        section={SelectedSection.Import}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Import master data'
                    >
                        <ImportSection />
                    </Section>
                    <Section
                        section={SelectedSection.Export}
                        selected={section}
                        onSelect={handleSetSection}
                        availableOnProd
                        title='Export master data'
                    >
                        <ExportSection />
                    </Section>
                    <Section
                        section={SelectedSection.SeedOrganization}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Create multisubsidiary organization'
                    >
                        <SeedOrganization />
                    </Section>
                    <Section
                        section={SelectedSection.SeedUsers}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Create random users'
                    >
                        <SeedRandomUsers />
                    </Section>
                    <Section
                        section={SelectedSection.SeedRequests}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Create random requests'
                    >
                        <SeedRandomOrders />
                    </Section>
                    <Section
                        section={SelectedSection.SeedItems}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Create random items'
                    >
                        <SeedRandomWarehouseItems />
                    </Section>
                    <Section
                        section={SelectedSection.SeedDemoOrganization}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Create and seed new demo organization'
                    >
                        <SeedDemoOrganization />
                    </Section>
                    <Section
                        section={SelectedSection.SeedRentalContracts}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Seed rental contracts and invoices'
                    >
                        <SeedRandomHofyAssets />
                    </Section>
                    <Section
                        section={SelectedSection.SeedNonHofyAssets}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Seed non-Hofy assets'
                    >
                        <SeedRandomNonHofyAssets />
                    </Section>
                    <Section
                        section={SelectedSection.SeedOrganizationWarehouses}
                        selected={section}
                        onSelect={handleSetSection}
                        title='Seed organization warehouses'
                    >
                        <SeedRandomOrganizationWarehouses />
                    </Section>
                </Box>
            </Box>
        </Box>
    );
};

interface SectionProps {
    section: SelectedSection;
    selected: SelectedSection | undefined;
    onSelect(v: SelectedSection): void;
    children: ReactNode;
    title: String;
    availableOnProd?: boolean;
}

const Section: FC<SectionProps> = ({ section, selected, onSelect, children, availableOnProd, title }) => {
    const isOpen = section === selected;
    if (isProd() && !availableOnProd) {
        return null;
    }

    return (
        <Accordion open={isOpen} title={title} onClick={() => onSelect(section)}>
            {children}
        </Accordion>
    );
};
