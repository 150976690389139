export const emailTopicI18n = {
    'email-topic.test-message': 'Test message',
    'email-topic.invite-manager': 'Invite manager',
    'email-topic.invite-member': 'Invite member',
    'email-topic.password-reset': 'Reset password',
    'email-topic.role-change': 'Change role',
    'email-topic.email-confirm': 'Confirm email',
    'email-topic.order-confirm': 'Order placed',
    'email-topic.order-request': 'Request order',
    'email-topic.order-approved': 'Approve order',
    'email-topic.order-sending': 'Sending order',
    'email-topic.prepaid-order-created': 'Prepaid order created',
    'email-topic.order-booked': 'Booked order',
    'email-topic.order-collected': 'Collected order',
    'email-topic.order-delivering': 'Delivering order',
    'email-topic.order-delivered': 'Delivered order',
    'email-topic.order-backorder': 'Backorder',
    'email-topic.issue-created': 'Created issue',

    'email-topic.collection-request': 'Collection request',
    'email-topic.collection-request-packaging-tracking-link': 'Collection request packaging tracking link',

    'email-topic.device-collection': 'Device collection',

    'email-topic.store-and-reuse-delivery-booked': 'Contract reassigned',
    'email-topic.store-and-reuse-collection-collected': 'Collecting for reuse',
    'email-topic.store-and-reuse-contract-available': 'Contract available for reuse',
    'email-topic.store-and-reuse-partner-delivery-booked': 'Partner contract reassigned',
    'email-topic.store-and-reuse-partner-collection-collected': 'Collecting partner order for reuse',
    'email-topic.store-and-reuse-partner-contract-available': 'Partner contract available for reuse',

    'email-topic.contract-almost-expiring': 'Contract almost expiring',
    'email-topic.asset-clearance-requested': 'Asset clearance requested',
    'email-topic.manage-refreshed-device': 'Manage refreshed device',
    'email-topic.hr-integration-issue-received': 'HR integration',
    'email-topic.new-hire-order-reminder': 'New hire order reminder',

    'email-topic.payment-receipt': 'Payment receipt',
    'email-topic.prepaid-order-payment-reminder': 'Prepaid order payment reminder',
    'email-topic.two-first-plan': 'Two first plan',

    'email-topic.invoice-created': 'Invoice created',
    'email-topic.credit-note-created': 'Credit note created',
    'email-topic.payment-reminder': 'Overdue invoice reminder',
    'email-topic.dunning-zero': 'Next payment reminder',
    'email-topic.dunning-one': 'Invoice due reminder',
    'email-topic.payment-failed': 'Payment failed',
    'email-topic.request-per-seat': 'Request per seat call',
    'email-topic.add-seats-request': 'Add seats request',
    'email-topic.enable-features-request': 'Enable features request',
};
