import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey, repaymentPlanService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useForceRecreateRejectedPlans = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: repaymentPlanService.forceRecreateRejectedPlans,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: 'Rejected plans re-created',
            });
        },
    });

    return {
        forceRecreate: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
