import React, { FC } from 'react';

import { BulkStorageOrderDto } from '@hofy/api-admin';
import { Box, CountryFlag, FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { useDownloadBulkStorageOrderDocument } from '../../../../store/bulkStorageOrders/useDownloadBulkStorageOrderDocument';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { DocumentFilesTable } from './DocumentFiles';

interface BulkStorageOrderDetailsContentProps {
    order: BulkStorageOrderDto;
}

export const BulkStorageOrderDetailsContent: FC<BulkStorageOrderDetailsContentProps> = ({ order }) => {
    const { isDownloading, download, isDownloadError } = useDownloadBulkStorageOrderDocument();

    return (
        <Box marginVertical={30} gap={30} column>
            <FormSection label='Bulk storage order details' marginRight={40} flex={1} overflow='hidden'>
                <FormGridRow columns={2}>
                    <LabeledText label='Public Id' content={order.publicId} />
                    <LabeledText label='Id' content={order.id} />
                </FormGridRow>
                <FormGridRow columns={2}>
                    <LabeledOrganizationLink organization={order.organization} />
                    <LabeledText label='Order Reference' content={order.orderReference} />
                </FormGridRow>
            </FormSection>

            <Box row alignItems='flex-start'>
                <FormSection label='Storage details' marginRight={40} flex={1} overflow='hidden'>
                    <FormGridRow columns={2}>
                        <Labeled
                            label='Warehouse'
                            content={
                                <Box row gap={8}>
                                    <CountryFlag country={order.toWarehouse.address?.country} />
                                    <Paragraph3>{order.toWarehouse.name}</Paragraph3>
                                </Box>
                            }
                        />
                    </FormGridRow>
                </FormSection>
            </Box>

            <FormSection label='Documents'>
                <Box overflow='auto'>
                    <DocumentFilesTable
                        files={order.documentFiles}
                        download={download}
                        isDownloading={isDownloading}
                        isDownloadError={isDownloadError}
                    />
                </Box>
            </FormSection>
            <AuditSection model={order} />
        </Box>
    );
};
