import React, { FC, ReactNode, useState } from 'react';

import { BulkStorageOrderDto, BulkStorageOrderItemDto, BulkStorageOrdersFilter } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import {
    Box,
    Chevron,
    defaultRowRenderer,
    ExpandHeight,
    FeaturedIcon,
    FilterApiRecord,
    FilterHeaderCell,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Link,
    Paragraph3,
    Placeholder,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';
import { LocationCard } from '@hofy/ui-domain';

import { BulkStorageOrderStatusChip } from '../../../../components/domain/bulkStorageOrders/BulkStorageOrderStatusChip';
import { getBulkStorageOrderLink } from '../../../../components/routing/adminLinks';
import { ActorLabel } from '../../../auditLogsPage/components/ActorLabel';
import { BulkStorageOrderActionMenu } from './BulkStorageOrderActionMenu';
import { BulkStorageOrderItemsTable } from './BulkStorageOrderItemsTable';

interface BulkStorageOrderTableProps {
    orders: BulkStorageOrderDto[];
    filters: FilterApiRecord<BulkStorageOrdersFilter>;
    onAssignOrder(id: UUID): void;
    onOrderDetails(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
}

export const BulkStorageOrderTable: FC<BulkStorageOrderTableProps> = ({
    orders,
    filters,
    onAssignOrder,
    onOrderDetails,
    infinityScroll,
}) => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const toggleExpand = (v: number) => {
        if (v === expandedIndex) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(v);
        }
    };

    return (
        <InfinityScrollTable
            data={orders}
            toKey={orders => orders.id}
            infinityScroll={infinityScroll}
            minWidth={1400}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Requests}
                    title='No bulk storage orders'
                    message='No bulk storage orders for selected criteria'
                />
            }
            rowRenderer={item => (
                <ExpandableBulkEntryRow
                    isExpanded={item.index === expandedIndex}
                    onClick={() => toggleExpand(item.index)}
                    key={item.index}
                    orderItems={item.item.items}
                >
                    {defaultRowRenderer(item)}
                </ExpandableBulkEntryRow>
            )}
            columns={[
                {
                    id: 'items',
                    header: 'Items',
                    width: 20,
                    renderer: (_, index) => <Chevron isOpen={index === expandedIndex} />,
                },
                {
                    id: 'bulk-storage-order',
                    header: 'Storage order #',
                    flexGrow: 1,
                    renderer: order => (
                        <Link onClick={() => onOrderDetails} to={getBulkStorageOrderLink(order.id)}>
                            {order.publicId}
                        </Link>
                    ),
                },
                {
                    id: 'orderReference',
                    header: 'Order reference',
                    flexGrow: 1,
                    renderer: order => order.orderReference,
                },
                {
                    id: 'organization',
                    header: 'Organisation',
                    flexGrow: 2,
                    renderer: order => order.organization.name,
                },
                {
                    id: 'location',
                    header: <FilterHeaderCell label='Location' filter={filters.warehouses} />,
                    flexGrow: 2,
                    renderer: ({ toWarehouse }) => (
                        <LocationCard
                            icon={
                                <FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant='vivid' />
                            }
                            label={toWarehouse.name}
                            name={toWarehouse.name}
                            country={toWarehouse.address?.country}
                        />
                    ),
                },
                {
                    id: 'expectedItems',
                    header: 'Exp. items',
                    renderer: order =>
                        order.items.reduce(
                            (previous: number, item: BulkStorageOrderItemDto) =>
                                previous + item.reservedQuantity,
                            0,
                        ),
                    width: 50,
                },
                {
                    id: 'createdAt',
                    header: 'Created at',
                    flexGrow: 1,
                    renderer: order => formatDate(order.createdAt),
                },
                {
                    id: 'createdBy',
                    header: 'Created by',
                    flexGrow: 1,
                    renderer: order => (
                        <Paragraph3>
                            <ActorLabel actor={order.createdBy} />
                        </Paragraph3>
                    ),
                },
                {
                    id: 'status',
                    header: <FilterHeaderCell label='Status' filter={filters.statuses} />,
                    flexGrow: 1,
                    renderer: order => (
                        <Box column gap={12}>
                            <BulkStorageOrderStatusChip status={order.status} dateTime={order.createdAt} />
                        </Box>
                    ),
                },
                {
                    id: 'actions',
                    header: '',
                    flexGrow: 0,
                    renderer: order => (
                        <BulkStorageOrderActionMenu onAssignOrder={onAssignOrder} order={order} />
                    ),
                },
            ]}
        />
    );
};

interface ExpandableBulkEntryRowProps {
    orderItems: BulkStorageOrderItemDto[];
    children: ReactNode;
    onClick(): void;
    isExpanded: boolean;
}

const ExpandableBulkEntryRow: FC<ExpandableBulkEntryRowProps> = ({
    children,
    onClick,
    isExpanded,
    orderItems,
}) => {
    return (
        <Box>
            <Box onClick={onClick} pointer>
                {children}
            </Box>
            <ExpandHeight>
                {isExpanded && <BulkStorageOrderItemsTable orderItems={orderItems} />}
            </ExpandHeight>
        </Box>
    );
};
