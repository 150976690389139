export const organizationPerSeatUpgradeModalI18n = {
    'organization-page.per-seat-upgrade-modal.title': 'Upgrade organization to per seat pricing',
    'organization-page.per-seat-upgrade-modal.description':
        'By confirming this action, you will upgrade this organization to per seat pricing.',
    'organization-page.per-seat-upgrade-modal.alert.title': 'This action is irreversible.',
    'organization-page.per-seat-upgrade-modal.alert.description':
        'Please end all current/future platform fee subscriptions before proceeding. If not actioned, the customer will be charged for both per seat and platform fee subscriptions.',
    'organization-page.per-seat-upgrade-modal.confirmation':
        'I understand the consequences of this action and I will end all current/future platform fee subscriptions.',
    'organization-page.per-seat-upgrade-modal.buttons.cancel': 'Cancel',
    'organization-page.per-seat-upgrade-modal.buttons.submit': 'Confirm',
};
