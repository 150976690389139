import React, { FC, useEffect, useState } from 'react';

import { BulkStorageOrderItemDto, useBulkStorageOrderQuery } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, ErrorStatePlaceholder } from '@hofy/ui';

import { useAssignBulkStorageOrderItems } from '../../../../store/bulkStorageOrders/useAssignBulkStorageOrderItems';
import { BulkStorageOrderItemsScanSelect } from './BulkStorageOrderItemsScanSelect';
import { BulkStorageOrderItemsScanForm } from './itemScanForm/BulkStorageOrderItemsScanForm';

interface ReceiveBulkStorageOrderSlideoutProps {
    id: UUID;
    onClose(): void;
}

export const ReceiveBulkStorageOrderSlideout: FC<ReceiveBulkStorageOrderSlideoutProps> = ({
    id,
    onClose,
}) => {
    const [bulkStorageOrderItem, setBulkStorageOrderItem] = useState<BulkStorageOrderItemDto | null>(null);

    const { data: bulkStorageOrder, isError } = useBulkStorageOrderQuery(id);

    const {
        form,
        items,
        setScansLeft,
        isItemEmpty,
        isLoadingMutation: assignItemSubmitting,
        isErrorMutation: assignItemError,
    } = useAssignBulkStorageOrderItems({
        onSuccess: () => setBulkStorageOrderItem(null),
        bulkStorageOrderItem: bulkStorageOrderItem,
    });

    useEffect(() => {
        if (bulkStorageOrderItem) {
            form.setValues({
                bulkStorageOrderItemId: bulkStorageOrderItem.id,
            });
            setScansLeft(bulkStorageOrderItem.reservedQuantity - bulkStorageOrderItem.assignedQuantity);
        }
    }, [bulkStorageOrderItem]);

    const handleSave = () => {
        if (bulkStorageOrder) {
            form.submit();
            return;
        }
        onClose();
    };
    const handleCancel = () => {
        if (bulkStorageOrder) {
            form.reset();
            setBulkStorageOrderItem(null);
            return;
        }
        onClose();
    };

    if (isError || !bulkStorageOrder) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Slideout width={1200} onClose={onClose}>
            <SlideoutHeader
                title={`Assign items at the warehouse for #${bulkStorageOrder.publicId}`}
                color={Color.ContentPrimary}
            />
            <SlideoutContent>
                {bulkStorageOrderItem ? (
                    <BulkStorageOrderItemsScanForm
                        bulkStorageOrderItem={bulkStorageOrderItem}
                        items={items}
                        isItemEmpty={isItemEmpty}
                        warehouse={bulkStorageOrder.toWarehouse}
                    />
                ) : (
                    <BulkStorageOrderItemsScanSelect
                        bulkStorageOrderId={bulkStorageOrder.id}
                        setBulkStorageOrderItem={setBulkStorageOrderItem}
                    />
                )}
            </SlideoutContent>

            <SlideoutFooter fullWidth justify='space-between'>
                {bulkStorageOrder && <Button type='secondary' label='Cancel' onClick={handleCancel} />}
                <Box flex={1} />
                <AsyncButton
                    isLoading={assignItemSubmitting}
                    isError={assignItemError}
                    onClick={handleSave}
                    label={!bulkStorageOrder ? 'Close' : 'Save'}
                    marginLeft={20}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
