import { UUID, zeroUuid } from '@hofy/global';

export interface SeedRandomOrganizationWarehousesPayload {
    organizationId: UUID;
    count: number;
}

export const emptySeedRandomOrganizationWarehousesPayload: SeedRandomOrganizationWarehousesPayload = {
    organizationId: zeroUuid,
    count: 0,
};
