import {
    difference,
    isArray,
    isBoolean,
    isEmpty,
    isEqual,
    isFinite,
    isNil,
    isNumber,
    isString,
} from 'lodash-es';

import {
    Decimal,
    findCountryByPhoneNumber,
    isEqualDecimal,
    isGreaterThanDecimal,
    isLessThanDecimal,
    percentToNumber,
    Phone,
    Price,
    validateUuid,
    zeroUuid,
} from '@hofy/global';
import { asArray, isValidEmail } from '@hofy/helpers';

import { Validator } from './validator';

type ValidationError<T extends object> = string | ((values: T) => string);

const validationErrorToString = <T extends object>(err: ValidationError<T>, formValues: T): string => {
    return err instanceof Function ? err(formValues) : err;
};

/**
 * Check if value is blank, empty, nil or false. Examples:
 * - True
 *   - `isBlank(undefined)`
 *   - `isBlank(null)`
 *   - `isBlank('')`
 *   - `isBlank(' ')`
 *   - `isBlank([])`
 *   - `isBlank({})`
 *   - `isBlank(false)`
 * - False
 *   - `isBlank(true)`
 *   - `isBlank(0)`
 */
export const isBlank = (value: unknown): boolean => {
    if (isNil(value)) {
        return true;
    }
    if (isBoolean(value)) {
        return value === false;
    }
    if (isString(value)) {
        return value.trim() === '';
    }
    if (isNumber(value)) {
        return !isFinite(value);
    }
    if (isArray(value)) {
        return value.length === 0;
    }
    return isEmpty(value);
};

export const is =
    <T extends object, K extends keyof T>(
        cond: (val: T[K], formValues: T) => boolean,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        return cond(value, formValues) ? undefined : validationErrorToString(err, formValues);
    };

export const isRequired =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        return !isBlank(value) ? undefined : validationErrorToString(err, formValues);
    };
export const isRequiredUuid =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value) || value === zeroUuid) {
            return validationErrorToString(err, formValues);
        }
        return undefined;
    };
export const isRequiredId =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value) || value === 0) {
            return validationErrorToString(err, formValues);
        }
        return undefined;
    };

export const isOneOf =
    <T extends object, K extends keyof T>(items: T[K] | T[K][], err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (Array.isArray(value)) {
            if (value.length === 0) {
                return undefined;
            }
            return difference(value, asArray(items)).length === 0
                ? undefined
                : validationErrorToString(err, formValues);
        }
        if (isBlank(value)) {
            return undefined;
        }
        return asArray(items).includes(value) ? undefined : validationErrorToString(err, formValues);
    };

export const minLength =
    <T extends object, K extends keyof T>(err: ValidationError<T>, minLength: number): Validator<T, K> =>
    (value, formValues) => {
        return (value as any[])?.length >= minLength ? undefined : validationErrorToString(err, formValues);
    };

export const isRequiredIf =
    <T extends object, K extends keyof T>(
        cond: (values: T) => boolean,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (!cond(formValues)) {
            return undefined;
        }
        return !isBlank(value)
            ? undefined
            : err instanceof Function
              ? err(formValues)
              : validationErrorToString(err, formValues);
    };
export const isNotSetIf =
    <T extends object, K extends keyof T>(
        cond: (values: T) => boolean,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (!cond(formValues)) {
            return undefined;
        }
        return isBlank(value)
            ? undefined
            : err instanceof Function
              ? err(formValues)
              : validationErrorToString(err, formValues);
    };
export const validateIf =
    <T extends object, K extends keyof T>(
        cond: (values: T) => boolean,
        validator: (value: T[K], formValue: T) => string | undefined,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (!cond(formValues)) {
            return undefined;
        }
        return validator(value, formValues);
    };

export const isRequiredIfFieldIsEmpty =
    <T extends object, K extends keyof T, K2 extends keyof T>(
        key: K2,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        return !isBlank(value) || !isBlank(formValues[key])
            ? undefined
            : validationErrorToString(err, formValues);
    };

export const isDifferentThanField =
    <T extends object, K extends keyof T, K2 extends keyof T>(
        key: K2,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return !isEqual(value, formValues[key]) ? undefined : validationErrorToString(err, formValues);
    };

export const isGreaterThan =
    <T extends object, K extends keyof T>(min: number, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return (value as number) > min ? undefined : validationErrorToString(err, formValues);
    };

export const isLessThan =
    <T extends object, K extends keyof T>(max: number, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return (value as number) < max ? undefined : validationErrorToString(err, formValues);
    };

export const isShorterThan =
    <T extends object, K extends keyof T>(maxLength: number, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return (value as string).length <= maxLength ? undefined : validationErrorToString(err, formValues);
    };

export const isLongerThan =
    <T extends object, K extends keyof T>(minLength: number, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return (value as string).length >= minLength ? undefined : validationErrorToString(err, formValues);
    };

export const isValidUuid =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (typeof value !== 'string' || !validateUuid(value)) {
            return validationErrorToString(err, formValues);
        }
        return undefined;
    };

export const isValidEmailAddress =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return isValidEmail(value as string) ? undefined : validationErrorToString(err, formValues);
    };

export const isValidPhone =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return (value as Phone).length >= 7 &&
            (value as Phone).length <= 15 &&
            findCountryByPhoneNumber(value as Phone) !== null
            ? undefined
            : validationErrorToString(err, formValues);
    };
export const isValidRegex =
    <T extends object, K extends keyof T>(
        regex: RegExp | undefined,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value) || !regex) {
            return undefined;
        }
        return regex.test(value as string) ? undefined : validationErrorToString(err, formValues);
    };

export const isValidCountryCode =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return findCountryByPhoneNumber(value as Phone) !== null
            ? undefined
            : validationErrorToString(err, formValues);
    };

export const isValidHttpsURL =
    <T extends object, K extends keyof T>(err: string): Validator<T, K> =>
    (value, formValues) => {
        if (!value) {
            return undefined;
        }
        let url;

        try {
            url = new URL((value as string) ?? '');
        } catch (_) {
            return err;
        }

        return url.protocol === 'https:' ? undefined : validationErrorToString(err, formValues);
    };

export const isValidPercent =
    <T extends object, K extends keyof T>(err: string): Validator<T, K> =>
    (value, formValues) => {
        if (!value) {
            return undefined;
        }

        const numericValue = percentToNumber(value as string);
        return numericValue >= 0 && numericValue <= 100
            ? undefined
            : validationErrorToString(err, formValues);
    };

export const isDecimalGreaterThan =
    <T extends object, K extends keyof T>(min: Decimal, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return isGreaterThanDecimal(value as Decimal, min)
            ? undefined
            : validationErrorToString(err, formValues);
    };

export const isDecimalLessThan =
    <T extends object, K extends keyof T>(max: Decimal, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return isLessThanDecimal(value as Decimal, max)
            ? undefined
            : validationErrorToString(err, formValues);
    };

export const isPriceGreaterThan =
    <T extends object, K extends keyof T>(min: Decimal, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return isDecimalGreaterThan(min, err)((value as Price).amount as T[K], formValues);
    };

export const isPriceGreaterThanOrEqual =
    <T extends object, K extends keyof T>(min: Decimal, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) =>
        isEqualDecimal((value as Price).amount, min)
            ? undefined
            : isPriceGreaterThan(min, err)(value, formValues);

export const isPriceLessThan =
    <T extends object, K extends keyof T>(max: Decimal, err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) => {
        if (isBlank(value)) {
            return undefined;
        }
        return isDecimalLessThan(max, err)((value as Price).amount as T[K], formValues);
    };

export const isPricePositive =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) =>
        isPriceGreaterThan('0.00', err)(value, formValues);

export const isPriceNegative =
    <T extends object, K extends keyof T>(err: ValidationError<T>): Validator<T, K> =>
    (value, formValues) =>
        isPriceLessThan('0.00', err)(value, formValues);

export const isPricePositiveIf =
    <T extends object, K extends keyof T>(
        cond: (values: T) => boolean,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (!cond(formValues)) {
            return undefined;
        }
        return isPricePositive(err)(value, formValues);
    };

export const isPriceNegativeIf =
    <T extends object, K extends keyof T>(
        cond: (values: T) => boolean,
        err: ValidationError<T>,
    ): Validator<T, K> =>
    (value, formValues) => {
        if (!cond(formValues)) {
            return undefined;
        }
        return isPriceNegative(err)(value, formValues);
    };
