import React, { FC, memo } from 'react';

import { SubscriptionDto, useTrSubscriptionTerm } from '@hofy/api-admin';
import { useTrPaymentSchema } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfiniteScrollConfig, InfinityScrollTable, Placeholder, SvgIllustration } from '@hofy/ui';

import { useDeleteSubscription } from '../../../../store/subscription/useDeleteSubscription';
import { useEndSubscription } from '../../../../store/subscription/useEndSubscription';
import { SubscriptionActions } from './SubscriptionActions';

interface SubscriptionsTableProps {
    organizationId: UUID;
    subscriptions: SubscriptionDto[];
    infinityScroll: InfiniteScrollConfig;
    onClick(id: UUID): void;
}

const SubscriptionsTableComponent: FC<SubscriptionsTableProps> = ({
    organizationId,
    subscriptions,
    infinityScroll,
    onClick,
}) => {
    const { formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();

    const { deleteSubscription } = useDeleteSubscription(organizationId);
    const { endSubscription } = useEndSubscription(organizationId);

    return (
        <InfinityScrollTable
            data={subscriptions}
            toKey={subscription => subscription.id}
            infinityScroll={infinityScroll}
            onRowClick={({ id }) => onClick(id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.List}
                    title='No subscriptions'
                    message='No subscriptions for organization'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'billingFrequency',
                    header: 'Billing frequency',
                    width: 300,
                    renderer: subscription => trPaymentSchema(subscription.billingFrequency),
                },
                {
                    id: 'term',
                    header: 'Term',
                    width: 200,
                    renderer: subscription => trTerm(subscription.term),
                },
                {
                    id: 'price',
                    header: 'Monthly price',
                    width: 200,
                    renderer: subscription => formatPrice(subscription.price),
                },
                {
                    id: 'startOn',
                    header: 'Start on',
                    width: 200,
                    renderer: subscription => formatDate(subscription.startOn),
                },
                {
                    id: 'endOn',
                    header: 'End on',
                    width: 200,
                    renderer: subscription => formatDate(subscription.endOn),
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    width: 200,
                    renderer: subscription => (
                        <SubscriptionActions
                            subscriptionId={subscription.id}
                            onDelete={deleteSubscription}
                            onEnd={endSubscription}
                        />
                    ),
                },
            ]}
        />
    );
};

export const SubscriptionsTable = memo(SubscriptionsTableComponent);
