import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UUID } from '@hofy/global';

import { organizationContractService } from '../service/organizationContractService';
import { SeatAssignmentDto } from '../service/types/SeatAssignmentDto';
import { organizationContractCacheKey } from './cacheKey';

export const useOrganizationContractSeats = (
    organizationId: UUID,
    organizationContractId: UUID,
    pageSize = 50,
) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [organizationContractCacheKey, organizationContractId, 'seats'],
        queryFn: param =>
            organizationContractService.listOrganizationContractSeats(
                organizationId,
                organizationContractId,
                {
                    page: param.pageParam,
                    pageSize,
                },
            ),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const seatAssignments: SeatAssignmentDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        seatAssignments,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
