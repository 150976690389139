import { AcquisitionType, AddonDto, ContractSettingDto, RentalTerm } from '@hofy/api-shared';
import { DiscountDto } from '@hofy/api-storefront-rest';
import { Country, Currency, Multiplier, Percent, Price, UUID } from '@hofy/global';

import { VariantDto } from './ProductDto';

export enum PurchaseOrderType {
    Warehouse = 'warehouse',
    Dropship = 'dropship',
}

export interface VariantWithProductAndPriceQuery {
    country: Country;
    currency: Currency;
    purchaseOrderType: PurchaseOrderType;
}

export interface VariantWithProductDto extends VariantDto {
    productName: string;
    targetPurchasePrice: Price | null;
}

export interface AdminProductPricingDetailsPayload extends AdminProductPricingDetails {
    organizationId: UUID;
}

interface AdminProductPricingDetails {
    country: Country | null;
    teamId: number | null;
    variantId: UUID;
    contractSettings: ContractSettingDto | null;
}

export interface AdminProductPricingDetailsDto extends ProductPricingDetails {}

interface ProductPricingDetails {
    //equipment: EquipmentPricingDto;
    service?: AddonDto;
    warranty?: AddonDto;
    storeFrontFeePrice: Price;
    storeFrontFeePercent: Percent;
    totalInvoice: Price;
    invoiceTax: Price;
    totalAmount: Price;
    totalTax: Price;
    totalAddons: Price;
    totalAddonsTax: Price;
    availableAddons: AddonDto[];
    variant: VariantDto;
    rentalTerm: RentalTerm | null;
    acquisitionType: AcquisitionType;
    // DEPRECATED: use product.availableAcquisitionTypes instead
    allowedAcquisitionTypes: AcquisitionType[];
    discount: DiscountDto;
    configFee: Price;
    configTax: Price;
    configTaxRate: Percent;
    // for bundle only
    countryPrice: Price;
    basePrice: Price;
    price: Price;
    countryMultiplier: Multiplier;
}
