import React, { FC, useState } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import {
    AddressDto,
    AssignmentCollectionReason,
    AssignmentConfigurationStatus,
    AssignmentStatus,
    AssignmentType,
    ContractEndOption,
    ContractType,
    ContractUnionDto,
    DataErasureStatus,
    isActiveOrPendingRentalStatus,
    isActivePurchaseStatus,
    isActiveRentalStatus,
    isCollectionPending,
    isDeliveryPending,
    isShipmentFromSupplierToUser,
    isShipmentFromUserToUser,
    isShipmentFromWarehouseToUser,
    isStoreAndReuseCategory,
    ItemStatus,
    Ownership,
    OwnershipDto,
    pendingAssignmentStatuses,
    Permission,
    ProductCategory,
    PurchaseContractStatus,
    RentalContractStatus,
    Role,
    ShipmentLocationAware,
    ShipmentStatus,
    ShipmentStatusAware,
} from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { Country, DateString, UUID } from '@hofy/global';
import { useLocalStorage } from '@hofy/hooks';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Box, BoxProps, ConfirmModal, Link, Modals, Paragraph3, SvgIcon } from '@hofy/ui';

import { useAssignmentRevertCompletion } from '../../../store/assignments/useAssignmentRevertCompletion';
import { useCancelAssignment } from '../../../store/assignments/useCancelAssignment';
import { useCancelAssignmentCollection } from '../../../store/assignments/useCancelAssignmentCollection';
import { useCancelCustomProduct } from '../../../store/assignments/useCancelCustomProduct';
import { useCancelStoreAndReuseCollection } from '../../../store/assignments/useCancelStoreAndReuseCollection';
import { useCancelStoreAndReuseDelivery } from '../../../store/assignments/useCancelStoreAndReuseDelivery';
import { useCancelSubstitution } from '../../../store/assignments/useCancelSubstitution';
import { useConvertLoanerToReplacement } from '../../../store/assignments/useConvertLoanerToReplacement';
import { useStoreAndReuseCountryQuery } from '../../../store/assignments/useStoreAndReuseCountryQuery';
import { useUpdateAssignmentOnHold } from '../../../store/assignments/useUpdateAssignmentOnHold';
import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useRemoveAssignmentsFromShipment } from '../../../store/shipments/useRemoveAssignmentsFromShipment';
import { AddLoanerModal } from '../../../views/assignmentSlideout/components/AddLoanerModal';
import { AddReplacementModal } from '../../../views/assignmentSlideout/components/AddReplacementModal';
import { AppendAssignmentToShipmentModal } from '../../../views/assignmentSlideout/components/AppendAssignmentToShipmentModal';
import { AssignmentCollectModal } from '../../../views/assignmentSlideout/components/AssignmentCollectModal';
import { AssignmentPurchaseModal } from '../../../views/assignmentSlideout/components/AssignmentPurchaseModal';
import { AssignmentShipmentIssueModal } from '../../../views/assignmentSlideout/components/AssignmentShipmentIssueModal';
import { AssignmentStolenModal } from '../../../views/assignmentSlideout/components/AssignmentStolenModal';
import { EnableStoreAndReuseCollectionModal } from '../../../views/assignmentSlideout/components/EnableStoreAndReuseCollectionModal';
import { EnableStoreAndReuseDeliveryModal } from '../../../views/assignmentSlideout/components/EnableStoreAndReuseDeliveryModal';
import { OrderItemDisposedModal } from '../../../views/assignmentSlideout/components/OrderItemDisposedModal';
import { UpdateCollectAssignmentModal } from '../../../views/assignmentSlideout/components/UpdateCollectAssignmentModal';
import { UpdateOtdModal } from '../../../views/assignmentSlideout/components/UpdateOtdModal';
import { getSalesOrderLink } from '../../routing/adminLinks';
import { PartOfSalesOrderModal } from '../salesOrders/PartOfSalesOrderModal';

enum AssignmentModal {
    CancelAssignmentModal = 'CancelAssignmentModal',
    PartOfSalesOrderModal = 'PartOfSalesOrderModal',
    RemoveAssignmentFromShipmentModal = 'RemoveAssignmentFromShipmentModal',
    AppendAssignmentToShipmentModal = 'AppendAssignmentToShipmentModal',
    UpdateCollectionReasonModal = 'UpdateCollectionReasonModal',
    AddReplacementModal = 'AddReplacementModal',
    CancelSubstitutionModal = 'CancelSubstitutionModal',
    CancelCustomProductModal = 'CancelCustomProductModal',
    AddLoanerModal = 'AddLoanerModal',
    EnableStoreAndReuseDeliveryModal = 'EnableStoreAndReuseDeliveryModal',
    CancelStoreAndReuseDeliveryModal = 'CancelStoreAndReuseDeliveryModal',
    CollectionModal = 'CollectionModal',
    CancelCollectionModal = 'CancelCollectionModal',
    EnableStoreAndReuseCollectionModal = 'EnableStoreAndReuseCollectionModal',
    CancelStoreAndReuseCollectionModal = 'CancelStoreAndReuseCollectionModal',
    ReportShipmentIssueModal = 'ReportShipmentIssueModal',
    ItemPurchaseModal = 'ItemPurchaseModal',
    ItemStolenModal = 'ItemStolenModal',
    ItemDisposeModal = 'ItemDisposeModal',
    UpdateOtdModal = 'UpdateOtdModal',
    RevertItemCompletionModal = 'RevertItemCompletionModal',
    ConvertLoanerToReplacementModal = 'ConvertLoanerToReplacementModal',
    UpdateAssignmentOnHoldModal = 'UpdateAssignmentOnHoldModal',
}

interface AssignmentMenuProps extends BoxProps {
    assignmentId: UUID;
    assignmentStatus: AssignmentStatus;
    assignmentType: AssignmentType;
    isPartner?: boolean;
    isStoreAndReuseDelivery: boolean;
    isStoreAndReuseCollection: boolean;
    isConsumable: boolean;
    hasCustomProduct: boolean;
    isOnHold: boolean;
    configurationStatus: AssignmentConfigurationStatus | null;
    dataErasureStatus: DataErasureStatus | null;
    collectionReason: AssignmentCollectionReason | null;
    productId: UUID;
    productCategory: ProductCategory;
    requestedVariantId: UUID | null;
    itemId: UUID | null;
    itemStatus: ItemStatus | null;
    itemOwnership: OwnershipDto | null;
    itemManagedByAssignmentContract: boolean;
    contract: ContractUnionDto | null;
    organizationId: UUID;
    isPendingShipment: boolean;
    collectionCountry: Country;
    deliveryCountry: Country;
    activeShipmentId: UUID | null;
    assignmentUserId: UUID | null;
    salesOrderId: UUID | null;
    activeShipment: (ShipmentLocationAware & ShipmentStatusAware) | null;
    deliveryAddress: AddressDto;
    userAddress: AddressDto;
    otd: DateString | null;
    onConfigureDevice(): void;
    onEraseDevice(): void;
    onSubstituteProduct(): void;
    onPickCustomProduct(): void;
    onRepairItem(): void;
    onCreateConsumable(): void;
}

export const AssignmentMenu: FC<AssignmentMenuProps> = ({
    assignmentId,
    assignmentStatus,
    assignmentType,
    isPartner,
    isStoreAndReuseDelivery,
    isOnHold,
    isStoreAndReuseCollection,
    isConsumable,
    hasCustomProduct,
    configurationStatus,
    dataErasureStatus,
    collectionReason,
    productId,
    productCategory,
    requestedVariantId,
    itemId,
    itemStatus,
    itemOwnership,
    itemManagedByAssignmentContract,
    contract,
    organizationId,
    isPendingShipment,
    collectionCountry,
    deliveryCountry,
    activeShipmentId,
    assignmentUserId,
    salesOrderId,
    activeShipment,
    deliveryAddress,
    userAddress,
    otd,
    onConfigureDevice,
    onEraseDevice,
    onSubstituteProduct,
    onPickCustomProduct,
    onRepairItem,
    onCreateConsumable,
    ...boxProps
}) => {
    const [modal, setModal] = useState<AssignmentModal>();
    const closeModal = () => setModal(undefined);

    const { hasPermission, hasRole } = usePermission();
    const canFulfill = hasPermission(Permission.AdminAssignmentsFulfill);
    const canViewConfiguration = hasPermission(Permission.AdminAssignmentsConfigurationView);
    const canViewDataErasure = hasPermission(Permission.AdminDataErasureView);
    const canUpdateOnHold = hasPermission(Permission.AdminAssignmentsUpdateOnHold);
    const canCreateRepair = hasPermission(Permission.AdminRepairsCreate);
    const isSuperAdmin = hasRole(Role.SuperAdmin);

    const isRental = !isConsumable && contract?.type === ContractType.Rental;
    const isPurchase = !isConsumable && contract?.type === ContractType.Purchase;
    const isManagement = contract?.type === ContractType.Management;

    const isOrganizationItem = itemOwnership?.type === Ownership.Organization;

    const canFulfillPendingDelivery = canFulfill && isDeliveryPending(assignmentStatus) && isPendingShipment;
    const isTransfer = isShipmentFromUserToUser(activeShipment);
    const isDeliveryWithCourier =
        (isShipmentFromWarehouseToUser(activeShipment) || isShipmentFromSupplierToUser(activeShipment)) &&
        activeShipment.status === ShipmentStatus.WithCourier;

    const isActiveRental =
        isRental && contract.rentalContract && isActiveRentalStatus(contract.rentalContract.status);
    const isActiveOrPendingRental =
        isRental && contract.rentalContract && isActiveOrPendingRentalStatus(contract.rentalContract.status);

    const isActivePurchase =
        isPurchase && contract.purchaseContract && isActivePurchaseStatus(contract.purchaseContract.status);

    const canCreateRepairForAssignment =
        canCreateRepair &&
        itemId !== null &&
        itemStatus === ItemStatus.Active &&
        !isOrganizationItem &&
        !isManagement &&
        !isConsumable;

    const canDisposeAssignment =
        assignmentStatus === AssignmentStatus.WithUser && !isManagement && canFulfill && !isConsumable;

    const {
        isStoreAndReuseCollectionEnabled,
        isStoreAndReuseCollectionToHubEnabled,
        isStoreAndReuseCollectionToLocalPartnerEnabled,
        isStoreAndReuseDeliveryEnabled,
        isLoading: storeAndReuseCountryLoading,
    } = useStoreAndReuseCountryQuery();

    const contractEndsOnCollection =
        isRental &&
        contract.rentalContract &&
        contract.rentalContract.isMain &&
        contract.rentalContract.executedContractEndOption &&
        contract.rentalContract.executedContractEndOption === ContractEndOption.Return;

    const canRemoveFromShipment =
        !isTransfer &&
        !isDeliveryWithCourier &&
        activeShipmentId &&
        canFulfill &&
        pendingAssignmentStatuses.includes(assignmentStatus) &&
        isPendingShipment;
    const canAppendShipment =
        !isTransfer &&
        canFulfill &&
        pendingAssignmentStatuses.includes(assignmentStatus) &&
        isPendingShipment;

    const storeAndReuseAllowed =
        hasPermission(Permission.AdminAssignmentsStoreAndReuse) &&
        assignmentType !== AssignmentType.Loaner &&
        isStoreAndReuseCategory(productCategory) &&
        !storeAndReuseCountryLoading &&
        !contractEndsOnCollection;

    const storeAndReuseCollectionAllowed =
        storeAndReuseAllowed && isStoreAndReuseCollectionEnabled(collectionCountry);
    const storeAndReuseDeliveryAllowed =
        storeAndReuseAllowed && isStoreAndReuseDeliveryEnabled(deliveryCountry);

    const isPendingNonManagementContract =
        (isRental && contract.rentalContract.status === RentalContractStatus.Pending) ||
        (isPurchase && contract.purchaseContract.status === PurchaseContractStatus.Pending);
    const canSubstituteContract =
        assignmentType === AssignmentType.Standard &&
        !isStoreAndReuseDelivery &&
        !isOrganizationItem &&
        !isManagement &&
        isPendingNonManagementContract;

    const [allowStoreAndReuseDeliveryWithItem] = useLocalStorage(
        'allow_enable_store_and_reuse_delivery_with_item',
        false,
    );

    const [auditLogMenuOption] = useAuditLogMenuOption(AuditableTable.Assignment, assignmentId);

    const menuItems = [
        {
            icon: SvgIcon.Laptop,
            action: onConfigureDevice,
            label: 'Configure product',
            visible: canViewConfiguration && configurationStatus !== null,
        },
        {
            icon: SvgIcon.Server,
            action: onEraseDevice,
            label: 'Erase device',
            visible: canViewDataErasure && dataErasureStatus !== null,
        },
        {
            icon: SvgIcon.Replace,
            action: onSubstituteProduct,
            label: 'Substitute product',
            visible: canSubstituteContract && requestedVariantId === null && canFulfillPendingDelivery,
        },
        {
            icon: SvgIcon.Replace,
            action: () => setModal(AssignmentModal.CancelSubstitutionModal),
            label: 'Cancel Substitution',
            visible:
                (canSubstituteContract && requestedVariantId !== null && canFulfillPendingDelivery) || false,
        },
        {
            icon: SvgIcon.Refresh,
            action: () => setModal(AssignmentModal.AddReplacementModal),
            label: 'Replace item',
            visible:
                assignmentStatus !== AssignmentStatus.Cancelled && canFulfill && !!contract && !isConsumable,
        },
        {
            icon: isOnHold ? SvgIcon.PackageRemove : SvgIcon.Package,
            action: () => setModal(AssignmentModal.UpdateAssignmentOnHoldModal),
            label: isOnHold ? 'Remove on hold' : 'Put on hold',
            visible: canUpdateOnHold && !activeShipmentId,
        },
        {
            icon: SvgIcon.Home,
            action: () => setModal(AssignmentModal.AddLoanerModal),
            label: 'Add loaner',
            visible:
                assignmentStatus !== AssignmentStatus.Cancelled && canFulfill && !!contract && !isConsumable,
        },
        {
            icon: SvgIcon.Chair,
            action: onPickCustomProduct,
            label: 'Pick custom product',
            visible:
                !hasCustomProduct && canFulfillPendingDelivery && !canSubstituteContract && !isManagement,
        },
        {
            icon: SvgIcon.Chair,
            action: () => setModal(AssignmentModal.CancelCustomProductModal),
            label: 'Remove custom product',
            visible: hasCustomProduct,
        },
        {
            icon: SvgIcon.Send,
            action: onCreateConsumable,
            label: 'Send consumable item',
            visible: !isConsumable && (isActiveRental || isActivePurchase),
        },
        {
            icon: SvgIcon.Globe,
            action: () => setModal(AssignmentModal.EnableStoreAndReuseDeliveryModal),
            label: 'Enable store and reuse delivery',
            visible:
                [AssignmentType.Replacement, AssignmentType.Standard].includes(assignmentType) &&
                !isStoreAndReuseDelivery &&
                (!itemId || allowStoreAndReuseDeliveryWithItem) &&
                isDeliveryPending(assignmentStatus) &&
                storeAndReuseDeliveryAllowed,
        },
        {
            icon: SvgIcon.Globe,
            action: () => setModal(AssignmentModal.CancelStoreAndReuseDeliveryModal),
            label: 'Cancel store and reuse delivery',
            visible:
                isStoreAndReuseDelivery &&
                isActiveOrPendingRental &&
                isDeliveryPending(assignmentStatus) &&
                storeAndReuseDeliveryAllowed,
        },
        {
            icon: SvgIcon.Undo,
            action: () => setModal(AssignmentModal.CollectionModal),
            label: 'Collect',
            visible: assignmentStatus === AssignmentStatus.WithUser && canFulfill && !isConsumable,
        },
        {
            icon: SvgIcon.Edit,
            action: () => setModal(AssignmentModal.UpdateCollectionReasonModal),
            label: 'Edit collection',
            visible: assignmentStatus === AssignmentStatus.CollectionPending && canFulfill,
        },
        {
            icon: SvgIcon.Globe,
            color: Color.NonContextualCyanDefault,
            action: () => setModal(AssignmentModal.EnableStoreAndReuseCollectionModal),
            label: 'Enable store and reuse collection',
            visible:
                !isStoreAndReuseCollection &&
                assignmentType !== AssignmentType.Loaner &&
                itemManagedByAssignmentContract &&
                (isCollectionPending(assignmentStatus) || assignmentStatus === AssignmentStatus.Collected) &&
                storeAndReuseCollectionAllowed,
        },
        {
            icon: SvgIcon.DollarSign,
            action: () => setModal(AssignmentModal.ItemPurchaseModal),
            label: 'Purchased by customer',
            visible:
                assignmentStatus === AssignmentStatus.WithUser &&
                canFulfill &&
                !isPartner &&
                assignmentType !== AssignmentType.Loaner &&
                isRental &&
                itemOwnership?.type === Ownership.Hofy,
        },
        {
            icon: SvgIcon.Undo,
            action: () => setModal(AssignmentModal.RevertItemCompletionModal),
            label: 'Revert to delivered',
            visible: assignmentStatus === AssignmentStatus.Collected && isSuperAdmin && !isPartner,
        },
        {
            icon: SvgIcon.Tool,
            action: onRepairItem,
            label: 'Repair item',
            visible: canCreateRepairForAssignment,
        },
        {
            icon: SvgIcon.Trash,
            action: () => setModal(AssignmentModal.ItemDisposeModal),
            label: 'Dispose of item',
            visible: canDisposeAssignment,
        },
        {
            icon: SvgIcon.Minus,
            action: () => setModal(AssignmentModal.ItemStolenModal),
            label: 'Lost or Stolen',
            visible:
                assignmentStatus === AssignmentStatus.WithUser &&
                !isManagement &&
                canFulfill &&
                !isConsumable,
        },
        {
            icon: SvgIcon.Truck,
            action: () => setModal(AssignmentModal.ReportShipmentIssueModal),
            label: 'Report delivery issue',
            visible:
                (assignmentStatus === AssignmentStatus.WithUser ||
                    (isDeliveryPending(assignmentStatus) && !isPendingShipment && !isManagement)) &&
                canFulfill,
        },
        {
            icon: SvgIcon.Cross,
            action: () => setModal(AssignmentModal.RemoveAssignmentFromShipmentModal),
            label: 'Remove from shipment',
            visible: !!canRemoveFromShipment,
        },
        {
            icon: SvgIcon.Add,
            action: () => setModal(AssignmentModal.AppendAssignmentToShipmentModal),
            label: activeShipmentId ? 'Move to another shipment' : 'Append to shipment',
            visible: canAppendShipment && !isOnHold,
        },
        {
            icon: SvgIcon.Calendar,
            action: () => setModal(AssignmentModal.UpdateOtdModal),
            label: 'Update OTD',
            visible: true,
        },
        {
            icon: SvgIcon.SwitchHorizontal,
            action: () => setModal(AssignmentModal.ConvertLoanerToReplacementModal),
            label: 'Convert to replacement',
            visible: hasRole(Role.SuperAdmin) && assignmentType === AssignmentType.Loaner,
        },
        {
            icon: SvgIcon.Cross,
            color: Color.ContentNegative,
            action: () => setModal(AssignmentModal.CancelCollectionModal),
            label: 'Cancel Collection',
            visible: assignmentStatus === AssignmentStatus.CollectionPending && canFulfill,
        },
        {
            icon: SvgIcon.Globe,
            color: Color.NonContextualBlueDefault,
            action: () => setModal(AssignmentModal.CancelStoreAndReuseCollectionModal),
            label: 'Cancel store and reuse collection',
            visible:
                isStoreAndReuseCollection &&
                (isCollectionPending(assignmentStatus) || assignmentStatus === AssignmentStatus.Collected) &&
                storeAndReuseCollectionAllowed,
        },
        {
            icon: SvgIcon.Cross,
            color: Color.ContentNegative,
            action: () => {
                setModal(
                    salesOrderId
                        ? AssignmentModal.PartOfSalesOrderModal
                        : AssignmentModal.CancelAssignmentModal,
                );
            },
            label: 'Cancel order',
            visible: canFulfillPendingDelivery,
        },
        auditLogMenuOption,
    ];

    return (
        <Box {...boxProps} onClick={e => e.stopPropagation()}>
            <MoreMenu items={menuItems} />
            <Modals>
                {modal && (
                    <AssignmentMenuModalRender
                        modal={modal}
                        onClose={closeModal}
                        organizationId={organizationId}
                        assignmentId={assignmentId}
                        activeShipmentId={activeShipmentId}
                        assignmentUserId={assignmentUserId}
                        contractId={contract?.id ?? null}
                        productId={productId}
                        salesOrderId={salesOrderId}
                        productCategory={productCategory}
                        assignmentStatus={assignmentStatus}
                        collectionReason={collectionReason}
                        collectionCountry={collectionCountry}
                        deliveryCountry={deliveryCountry}
                        userAddress={userAddress}
                        deliveryAddress={deliveryAddress}
                        otd={otd}
                        storeAndReuseCollectionAllowed={storeAndReuseCollectionAllowed}
                        isActiveRental={isActiveOrPendingRental}
                        isOnHold={isOnHold}
                        isStoreAndReuseCollectionToHubEnabled={isStoreAndReuseCollectionToHubEnabled(
                            collectionCountry,
                        )}
                        isStoreAndReuseCollectionToLocalPartnerEnabled={isStoreAndReuseCollectionToLocalPartnerEnabled(
                            collectionCountry,
                        )}
                    />
                )}
            </Modals>
        </Box>
    );
};

interface AssignmentMenuModalRenderProps {
    modal: AssignmentModal | undefined;
    onClose(): void;

    organizationId: UUID;
    assignmentId: UUID;
    activeShipmentId: UUID | null;
    assignmentUserId: UUID | null;
    salesOrderId: UUID | null;
    contractId: UUID | null;
    productId: UUID;
    productCategory: ProductCategory;

    assignmentStatus: AssignmentStatus;
    collectionReason: AssignmentCollectionReason | null;

    collectionCountry: Country;
    deliveryCountry: Country;
    otd: DateString | null;

    userAddress: AddressDto;
    deliveryAddress: AddressDto;

    isActiveRental: boolean;
    isOnHold: boolean;

    storeAndReuseCollectionAllowed: boolean;
    isStoreAndReuseCollectionToHubEnabled: boolean;
    isStoreAndReuseCollectionToLocalPartnerEnabled: boolean;
}

const AssignmentMenuModalRender: FC<AssignmentMenuModalRenderProps> = ({
    modal,
    onClose,
    organizationId,
    assignmentId,
    activeShipmentId,
    assignmentUserId,
    contractId,
    productId,
    productCategory,
    salesOrderId,

    assignmentStatus,
    collectionReason,

    collectionCountry,
    userAddress,
    deliveryAddress,
    deliveryCountry,
    otd,

    isActiveRental,
    isOnHold,

    storeAndReuseCollectionAllowed,
    isStoreAndReuseCollectionToHubEnabled,
    isStoreAndReuseCollectionToLocalPartnerEnabled,
}) => {
    const { cancel } = useCancelAssignment();
    const { updateOnHold } = useUpdateAssignmentOnHold(assignmentId);
    const { cancelSubstitution } = useCancelSubstitution(assignmentId);
    const { cancelCustomProduct } = useCancelCustomProduct(assignmentId);
    const { cancelAssignmentCollection } = useCancelAssignmentCollection();
    const { cancelStoreAndReuseDelivery } = useCancelStoreAndReuseDelivery();
    const { cancelStoreAndReuseCollection } = useCancelStoreAndReuseCollection();
    const { removeAssignmentFromShipment } = useRemoveAssignmentsFromShipment(activeShipmentId);
    const { assignmentRevertCompletion } = useAssignmentRevertCompletion();
    const { convertLoanerToReplacement } = useConvertLoanerToReplacement();

    switch (modal) {
        case AssignmentModal.CancelAssignmentModal:
            return (
                <ConfirmModal
                    keyPrefix='cancel-order-modal'
                    onClose={onClose}
                    onConfirm={() => cancel(assignmentId)}
                />
            );
        case AssignmentModal.PartOfSalesOrderModal:
            return (
                <PartOfSalesOrderModal
                    onClose={onClose}
                    title="Assignment can't be cancelled"
                    content={
                        <>
                            This assignment is part of a sales order. <br />
                            In order to cancel the assignment, you must cancel the sales order{' '}
                            <Link to={getSalesOrderLink(salesOrderId)} inline>
                                #{salesOrderId}
                            </Link>
                        </>
                    }
                />
            );
        case AssignmentModal.CancelCollectionModal:
            return (
                <ConfirmModal
                    keyPrefix='cancel-collection-modal'
                    onClose={onClose}
                    onConfirm={() => cancelAssignmentCollection(assignmentId)}
                />
            );
        case AssignmentModal.AddReplacementModal:
            return (
                <AddReplacementModal
                    assignmentId={assignmentId}
                    deliveryAddress={deliveryAddress}
                    userAddress={userAddress}
                    onClose={onClose}
                />
            );
        case AssignmentModal.CancelSubstitutionModal:
            return (
                <ConfirmModal
                    onClose={onClose}
                    keyPrefix='cancel-substitute-product-modal'
                    onConfirm={cancelSubstitution}
                />
            );
        case AssignmentModal.CancelCustomProductModal:
            return (
                <ConfirmModal
                    onClose={onClose}
                    keyPrefix='cancel-custom-product-modal'
                    onConfirm={cancelCustomProduct}
                />
            );
        case AssignmentModal.AppendAssignmentToShipmentModal:
            return (
                <AppendAssignmentToShipmentModal
                    onSuccess={onClose}
                    assignmentId={assignmentId}
                    currentShipmentId={activeShipmentId}
                    toUserId={isDeliveryPending(assignmentStatus) ? assignmentUserId : null}
                    fromUserId={
                        assignmentStatus === AssignmentStatus.CollectionPending ? assignmentUserId : null
                    }
                />
            );
        case AssignmentModal.UpdateOtdModal:
            return <UpdateOtdModal onSuccess={onClose} assignmentId={assignmentId} assignmentOtd={otd} />;
        case AssignmentModal.ConvertLoanerToReplacementModal:
            return (
                <ConfirmModal
                    keyPrefix='convert-loaner-to-replacement-modal'
                    onClose={onClose}
                    onConfirm={() => convertLoanerToReplacement(assignmentId)}
                />
            );
        case AssignmentModal.RemoveAssignmentFromShipmentModal:
            return (
                <ConfirmModal
                    keyPrefix='remove-assignment-from-shipment-modal'
                    onClose={onClose}
                    onConfirm={() => removeAssignmentFromShipment([assignmentId])}
                />
            );
        case AssignmentModal.ItemPurchaseModal:
            if (!contractId) {
                return null;
            }
            return (
                <AssignmentPurchaseModal
                    assignmentId={assignmentId}
                    contractId={contractId}
                    onSuccess={onClose}
                />
            );
        case AssignmentModal.ItemStolenModal:
            return (
                <AssignmentStolenModal
                    onSuccess={onClose}
                    assignmentId={assignmentId}
                    userAddress={userAddress}
                    deliveryAddress={deliveryAddress}
                />
            );
        case AssignmentModal.ItemDisposeModal:
            return <OrderItemDisposedModal onSuccess={onClose} assignmentId={assignmentId} />;
        case AssignmentModal.AddLoanerModal:
            return (
                <AddLoanerModal
                    assignmentId={assignmentId}
                    deliveryAddress={deliveryAddress}
                    userAddress={userAddress}
                    onClose={onClose}
                />
            );
        case AssignmentModal.UpdateCollectionReasonModal:
            return (
                <UpdateCollectAssignmentModal
                    assignmentId={assignmentId}
                    collectionReason={collectionReason}
                    onSuccess={onClose}
                />
            );
        case AssignmentModal.CollectionModal:
            return (
                <AssignmentCollectModal
                    assignmentId={assignmentId}
                    isActiveRental={isActiveRental}
                    storeAndReuseAllowed={storeAndReuseCollectionAllowed}
                    onSuccess={onClose}
                    userAddress={userAddress}
                    deliveryAddress={deliveryAddress}
                />
            );
        case AssignmentModal.ReportShipmentIssueModal:
            return (
                <AssignmentShipmentIssueModal
                    onSuccess={onClose}
                    assignmentId={assignmentId}
                    userAddress={userAddress}
                    deliveryAddress={deliveryAddress}
                />
            );
        case AssignmentModal.EnableStoreAndReuseDeliveryModal:
            if (!contractId) {
                return null;
            }
            return (
                <EnableStoreAndReuseDeliveryModal
                    onSuccess={onClose}
                    assignmentId={assignmentId}
                    productId={productId}
                    productCategory={productCategory}
                    contractId={contractId}
                    organizationId={organizationId}
                    country={deliveryCountry}
                />
            );
        case AssignmentModal.CancelStoreAndReuseDeliveryModal:
            return (
                <ConfirmModal
                    keyPrefix='cancel-store-and-reuse-delivery-modal'
                    onClose={onClose}
                    onConfirm={() => cancelStoreAndReuseDelivery(assignmentId)}
                />
            );
        case AssignmentModal.EnableStoreAndReuseCollectionModal:
            return (
                <EnableStoreAndReuseCollectionModal
                    organizationId={organizationId}
                    isActiveRental={isActiveRental}
                    contractId={contractId}
                    onSuccess={onClose}
                    assignmentId={assignmentId}
                    country={collectionCountry}
                    isStoreAndReuseCollectionToHubEnabled={isStoreAndReuseCollectionToHubEnabled}
                    isStoreAndReuseCollectionToLocalPartnerEnabled={
                        isStoreAndReuseCollectionToLocalPartnerEnabled
                    }
                />
            );
        case AssignmentModal.CancelStoreAndReuseCollectionModal:
            return (
                <ConfirmModal
                    keyPrefix='cancel-store-and-reuse-collection-modal'
                    onClose={onClose}
                    onConfirm={() => cancelStoreAndReuseCollection(assignmentId)}
                />
            );
        case AssignmentModal.RevertItemCompletionModal:
            return (
                <ConfirmModal
                    keyPrefix='assignment-revert-completion-modal'
                    action='destructive'
                    onClose={onClose}
                    onConfirm={() => assignmentRevertCompletion(assignmentId)}
                >
                    <Paragraph3 marginVertical={10}>Item ownership will be changed to hofy</Paragraph3>
                </ConfirmModal>
            );
        case AssignmentModal.UpdateAssignmentOnHoldModal:
            return (
                <ConfirmModal
                    keyPrefix={isOnHold ? 'remove-assignment-on-hold-modal' : 'put-assignment-on-hold-modal'}
                    onClose={onClose}
                    onConfirm={() => updateOnHold(!isOnHold)}
                />
            );
    }
    return null;
};
