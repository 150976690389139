import { DateTimeString } from '@hofy/global';

export interface HrisConfigDto {
    platform: string;
    lastSync: DateTimeString | null;
    status: HrisStatus;
    errorDescriptions: string[];
}

export enum HrisStatus {
    Complete = 'complete',
    Incomplete = 'incomplete',
    RelinkNeeded = 'relink_needed',
}
