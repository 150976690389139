import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    BulkStorageOrderItemPayload,
    BulkStorageOrderPayload,
    bulkStorageOrdersCacheKey,
    bulkStorageOrderService,
} from '@hofy/api-admin';
import { Currency } from '@hofy/global';
import {
    isRequired,
    useForm,
    useFormArrayField,
    useMultipartForm,
    useToast,
    validateArrayField,
    validator,
} from '@hofy/ui';

import {
    BulkStorageOnlyItemsFormData,
    BulkStorageOnlyItemsFormValidData,
    BulkStorageOnlyOrderFormData,
    BulkStorageOnlyOrderFormValidData,
    BulkStorageOrderMultipartForm,
    BulkStorageOrderMultipartValidForm,
    emptyBulkStorageOrderFormData,
    emptyBulkStorageOrderItemFormData,
    WizardSteps,
} from './types/BulkStorageOrderFormData';

export const useCreateBulkStorageOrder = (onClose: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: bulkStorageOrderService.createBulkStorageOrder,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [bulkStorageOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Bulk storage order created',
            });
            onClose();
        },
    });

    const orderForm = useForm<BulkStorageOnlyOrderFormData, BulkStorageOnlyOrderFormValidData>({
        initial: emptyBulkStorageOrderFormData,
        validate: validator<BulkStorageOrderPayload>({
            orderReference: isRequired('Order reference is required'),
            organizationId: isRequired('Organization is required'),
            toWarehouseId: isRequired('Warehouse is required'),
        }),
    });
    const itemsForm = useForm<BulkStorageOnlyItemsFormData, BulkStorageOnlyItemsFormValidData>({
        initial: { items: [] },
        validate: validator<BulkStorageOrderPayload>({
            items: validateArrayField<BulkStorageOrderPayload, 'items'>({
                selfRules: isRequired('At least one product is required'),
                fieldsValidator: validator<BulkStorageOrderItemPayload>({
                    variantId: isRequired('SKU is required'),
                    expectedQuantity: isRequired('Quantity is required'),
                }),
            }),
        }),
        validateDeps: [orderForm.values.organizationId],
    });

    const items = useFormArrayField(itemsForm.fields.items, emptyBulkStorageOrderItemFormData(Currency.USD));
    return {
        form: useMultipartForm<BulkStorageOrderMultipartForm, BulkStorageOrderMultipartValidForm>(
            { [WizardSteps.Order]: orderForm, [WizardSteps.Items]: itemsForm },
            {
                onSubmit: values => {
                    mutation.mutate({
                        ...values.order,
                        ...values.items,
                    });
                },
            },
        ),
        items,
        isSubmitting: mutation.isPending,
        onDiscard: onClose,
    };
};
