import { UUID } from '@hofy/global';

import { Image } from '../../../components/design/imageEditor/types/Image';

export const createUrlsFromBlobImage = (
    images: Record<UUID, Blob>,
    imageIds: UUID[],
    activeIndex: number = -1,
) => {
    const urls: Image[] = imageIds.map((id, index) => ({
        id,
        url: images[id] ? URL.createObjectURL(images[id]) : '',
        isActive: index === activeIndex,
    }));

    return urls;
};
