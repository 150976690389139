import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { Box, Form, LabeledInput, SubmitButton } from '@hofy/ui';

import { LabeledOrganizationSelect } from '../../../../components/domain/organizations/LabeledOrganizationSelect';
import { useSeedOrganizationWarehouse } from '../../../../store/settings/useSeedOrganizationWarehouse';

export const SeedRandomOrganizationWarehouses: FC = () => {
    const { form, isLoading } = useSeedOrganizationWarehouse();

    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <LabeledOrganizationSelect
                    label='Organization'
                    onChange={(organizationId: UUID) => form.setValues({ organizationId })}
                    value={form.values.organizationId}
                    placeholder='Choose organization'
                    nullable={false}
                    errorMessage={form.currentErrors.organizationId?.toString()}
                    isRequired
                />
                <LabeledInput
                    label='Number of assets'
                    type='number'
                    onChange={numberOfAssets => form.setValues({ count: parseInt(numberOfAssets) })}
                    value={form.values.count.toString()}
                    placeholder='Enter number of contracts'
                    errorMessage={form.currentErrors.count?.toString()}
                    isRequired
                />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
