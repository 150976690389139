import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    AddSeatsToOrganizationContractPayload,
    organizationContractCacheKey,
    organizationContractService,
} from '@hofy/api-admin';
import { Price, UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { useAddSeatsForm } from './useAddSeatsForm';

export const useAddSeats = (
    organizationId: UUID,
    organizationContractId: UUID,
    currentSeatPrice: Price,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: AddSeatsToOrganizationContractPayload) => {
            return organizationContractService.addSeats(organizationId, organizationContractId, payload);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [organizationContractCacheKey, organizationContractId],
            });
            showToast({
                type: 'positive',
                message: 'Seats added successfully',
            });
            onSuccess();
        },
    });

    return useAddSeatsForm(mutation, currentSeatPrice);
};
