import { BulkStorageOrderPayload } from '@hofy/api-admin';
import { AcquisitionType, ContractSettingDto, DocumentFilesPayload, PaymentSchema } from '@hofy/api-shared';
import { Currency, Price, UUID, zeroPrice } from '@hofy/global';
import { UseForm } from '@hofy/ui';

export interface BulkStorageOrderFormData {
    toWarehouseId: UUID | null;
    organizationId: UUID | null;
    items: BulkStorageOrderFormItemData[];
    orderReference: string | null;
    documentFiles: DocumentFilesPayload;
}
export interface BulkStorageOrderFormItemData {
    variantId: UUID | null;
    expectedQuantity: number;
    contractSettings: ContractSettingDto;

    customSourcingFee: Price;
    storageFee: Price;
    activationFee: Price;
    gracePeriodInMonths: number;
}

export type BulkStorageOnlyOrderFormData = Omit<BulkStorageOrderFormData, 'items'>;
export type BulkStorageOnlyItemsFormData = Pick<BulkStorageOrderFormData, 'items'>;

export type BulkStorageOnlyOrderFormValidData = Omit<BulkStorageOrderPayload, 'items'>;
export type BulkStorageOnlyItemsFormValidData = Pick<BulkStorageOrderPayload, 'items'>;

export enum WizardSteps {
    Order = 'order',
    Items = 'items',
}

export type BulkStorageOrderMultipartForm = {
    [key in WizardSteps]: UseForm<any>;
};

export interface BulkStorageOrderMultipartValidForm extends BulkStorageOrderMultipartForm {
    [WizardSteps.Order]: UseForm<BulkStorageOnlyOrderFormValidData>;
    [WizardSteps.Items]: UseForm<BulkStorageOnlyItemsFormValidData>;
}

export const emptyBulkStorageOrderItemFormData = (currency: Currency): BulkStorageOrderFormItemData => ({
    variantId: null,
    expectedQuantity: 1,
    contractSettings: {
        acquisitionType: AcquisitionType.Purchase,
        rentalTerm: null,
        paymentSchema: null,
        servicePaymentSchema: PaymentSchema.Upfront,
        warrantyPaymentSchema: PaymentSchema.Upfront,
        serviceBundleId: null,
        warrantyBundleId: null,
        serviceDuration: null,
    },
    customSourcingFee: zeroPrice(currency),
    storageFee: zeroPrice(currency),
    activationFee: zeroPrice(currency),
    gracePeriodInMonths: 6,
});

export const emptyBulkStorageOrderFormData: Omit<BulkStorageOrderFormData, 'items'> = {
    organizationId: null,
    toWarehouseId: null,
    orderReference: null,
    documentFiles: [],
};
