import React, { FC } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { BulkStorageOrdersPage } from './BulkStorageOrdersPage';
import { NewBulkStorageOrderSlideout } from './createSlideout/NewBulkStorageOrderSlideout';
import { BulkStorageOrderDetailsSlideout } from './detailsSlideout/BulkStorageOrderDetailsSlideout';
import { ReceiveBulkStorageOrderSlideout } from './receiveSlideout/ReceiveBulkStorageOrderSlideout';

export const BulkStorageOrdersRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const handleSlideoutClose = () => history.push(`${path}${history.location.search}`);

    const handleNewBulkStorageOrder = () => {
        history.push(`${path}/new${history.location.search}`);
    };

    const handleBulkStorageOrderDetails = (id: UUID) => {
        history.push(`${path}/${id}/details${history.location.search}`);
    };

    const handleReceiveBulkStorageOrder = (id: UUID) => {
        history.push(`${path}/${id}/receive${history.location.search}`);
    };

    return (
        <>
            <BulkStorageOrdersPage
                onNewOrder={handleNewBulkStorageOrder}
                onAssignOrder={handleReceiveBulkStorageOrder}
                onOrderDetails={handleBulkStorageOrderDetails}
            />
            <Switch>
                <ReceiveBulkStorageOrderRoute exact path={`${path}/:id(${pathUuid})/receive`}>
                    {({ id }) => <ReceiveBulkStorageOrderSlideout onClose={handleSlideoutClose} id={id} />}
                </ReceiveBulkStorageOrderRoute>
                <BulkStorageOrderDetailsRoute exact path={`${path}/:id(${pathUuid})/details`}>
                    {({ id }) => (
                        <BulkStorageOrderDetailsSlideout orderId={id} onClose={handleSlideoutClose} />
                    )}
                </BulkStorageOrderDetailsRoute>
                <Route exact path={`${path}/new`}>
                    <NewBulkStorageOrderSlideout onClose={handleSlideoutClose} />
                </Route>
            </Switch>
        </>
    );
};

const ReceiveBulkStorageOrderRoute = UUIDRoute('id');
const BulkStorageOrderDetailsRoute = UUIDRoute('id');
