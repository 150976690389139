import { useMutation } from '@tanstack/react-query';

import { invoiceEntryService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSimulateTaxComputation = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: invoiceEntryService.simulateTaxComputation,
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Simulation for tax computation scheduled',
            });
        },
    });

    return {
        simulateTaxComputation: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
