import React, { FC, useMemo } from 'react';

import { useSubscriptions } from '@hofy/api-admin';
import { SubscriptionType } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { isDateInFuture } from '@hofy/helpers';
import { Alert, Form, SubmitButton } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { Footer } from '../../../components/design/layout/Footer';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useCreateOrganizationContract } from '../../../store/organizationContracts/useCreateOrganizationContract';
import { OrganizationContractForm } from './OrganizationContractForm';

interface CreateOrganizationContractOverlayProps {
    onClose(): void;
    organizationId: UUID;
}

export const CreateOrganizationContractOverlay: FC<CreateOrganizationContractOverlayProps> = ({
    onClose,
    organizationId,
}) => {
    const title = 'Create organization contract';
    const organizationContractForm = useCreateOrganizationContract(onClose, organizationId);
    const { tr } = useAdminI18n();

    const { subscriptions } = useSubscriptions(organizationId, { types: [SubscriptionType.TypePlatform] });
    const futureSubscriptions = useMemo(
        () => subscriptions.filter(subscription => isDateInFuture(subscription.endOn)),
        [subscriptions],
    );

    return (
        <Slideout width={600} onClose={onClose} slideoutId='add-organization-contract'>
            <SlideoutHeader title={title} />
            <SlideoutContent>
                <Form
                    onSubmit={organizationContractForm.form.submit}
                    isLoading={organizationContractForm.isLoading}
                    isError={organizationContractForm.isError}
                    flex='auto'
                    column
                >
                    <OrganizationContractForm organizationContractForm={organizationContractForm} />

                    {futureSubscriptions.length > 0 && (
                        <Alert
                            marginBottom={40}
                            title={tr(
                                'organization-page.create-organization-contract.platform-fee-alert-title',
                            )}
                            description={tr(
                                'organization-page.create-organization-contract.platform-fee-alert-description',
                            )}
                            type='negative'
                        />
                    )}

                    <Footer>
                        <CancelButton onClick={onClose} label='Cancel' />
                        <SubmitButton label='Save' />
                    </Footer>
                </Form>
            </SlideoutContent>
        </Slideout>
    );
};
