import { useMutation } from '@tanstack/react-query';

import { bulkStorageOrderService, BulkStorageOrdersFilter } from '@hofy/api-admin';

export const useBulkStorageOrdersExport = (filters: BulkStorageOrdersFilter) => {
    const { mutate, isPending } = useMutation({
        mutationFn: () => bulkStorageOrderService.exportBulkStorageOrders(filters),
        mutationKey: [bulkStorageOrderService, 'export'],
    });
    return {
        download: mutate,
        isDownloading: isPending,
    };
};
