import { AddSeatsToOrganizationContractPayload, initialAddSeatsPayload } from '@hofy/api-admin';
import { Price } from '@hofy/global';
import { isGreaterThan, isPriceGreaterThanOrEqual, isRequired, useForm, useToast, validator } from '@hofy/ui';

interface AddUsersMutation {
    mutate(payload: AddSeatsToOrganizationContractPayload): void;
    isPending: boolean;
    isError: boolean;
}

export const useAddSeatsForm = (mutation: AddUsersMutation, initialPrice: Price) => {
    const { showToast } = useToast();
    const form = useForm<AddSeatsToOrganizationContractPayload>({
        initial: initialAddSeatsPayload(initialPrice),
        onSubmit: mutation.mutate,
        validate: validator<AddSeatsToOrganizationContractPayload>({
            seats: isGreaterThan(0, 'Must be greater than 0'),
            price: [
                isPriceGreaterThanOrEqual('0.0', 'Price must be positive or zero'),
                isRequired('Price is required'),
            ],
        }),
        onInvalid(error) {
            if (error.seats) {
                showToast({
                    type: 'negative',
                    message: error.seats.toString(),
                });
            }
        },
    });

    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        form,
    };
};

export type UseAddSeatsForm = ReturnType<typeof useAddSeatsForm>;
