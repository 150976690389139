import { BulkStorageOrderStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export interface BulkStorageOrdersFilter {
    search: string;
    warehouses: UUID[];
    statuses: BulkStorageOrderStatus[];
}

export const emptyBulkStorageOrdersFilter: BulkStorageOrdersFilter = {
    search: '',
    warehouses: [],
    statuses: [],
};
