import { AddonItem, AddonTarget, AddonType } from '@hofy/api-shared';

import {
    AddonConstraintDto,
    AddonDataDependenciesDto,
    AddonDiscountDataDto,
    AddonPoolDataDto,
    AddonPricingDataDto,
} from './AddonDetailsDto';

export interface AddonPayload {
    name: string;
    publicName: string;
    services: AddonItem[];
    type: AddonType;
    target: AddonTarget;
    isPublic: boolean;
    isPremium: boolean;
    constraints: AddonConstraintPayload[];
    pricings: AddonPricingPayload[];
    discounts: AddonDiscountPayload[];
    pools: AddonPoolPayload[];
}

type AddonConstraintPayload = AddonConstraintDto;

export const emptyConstraintsPayload: AddonConstraintPayload = {
    contractType: null,
    rentalTerm: null,
    durations: null,
    paymentSchemas: null,
    productParentCategories: null,
    productCategories: null,
    productBrands: null,
    assetAgeMax: null,
    platformTiers: null,
};

type AddonDependenciesPayload = AddonDataDependenciesDto;

export const emptyDependenciesPayload: AddonDependenciesPayload = {
    durations: null,
    paymentSchemas: null,
    productCategories: null,
    assetValue: null,
    platformTiers: null,
};

type AddonPricingPayload = AddonPricingDataDto;

type AddonDiscountPayload = AddonDiscountDataDto;

type AddonPoolPayload = AddonPoolDataDto;
