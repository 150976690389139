import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader, WizardSimpleFooter } from '@hofy/common';

import { WizardSteps } from '../../../../store/bulkStorageOrders/types/BulkStorageOrderFormData';
import { useCreateBulkStorageOrder } from '../../../../store/bulkStorageOrders/useCreateBulkStorageOrder';
import { CreateBulkStorageOrderForm } from './CreateBulkStorageOrderForm';

interface NewBulkStorageOrderSlideoutProps {
    onClose(): void;
}

const steps = [WizardSteps.Order, WizardSteps.Items];

export const NewBulkStorageOrderSlideout: FC<NewBulkStorageOrderSlideoutProps> = ({ onClose }) => {
    const { form, items, isSubmitting } = useCreateBulkStorageOrder(onClose);

    const customOnChangeStep = (step: WizardSteps) => {
        if (step === WizardSteps.Order) {
            form.forms[WizardSteps.Items].reset();
        }
        form.goToStep(step);
    };

    return (
        <Slideout width={1000} onClose={onClose} slideoutId='bulk-storage-order'>
            <SlideoutHeader title='New bulk storage order' />
            <SlideoutContent>
                <CreateBulkStorageOrderForm form={form} items={items} step={form.step} />
            </SlideoutContent>
            <SlideoutFooter>
                <WizardSimpleFooter
                    step={form.step.toString()}
                    steps={steps}
                    onCancel={onClose}
                    nextLabel='Next'
                    cancelLabel='Cancel'
                    prevLabel='Previous'
                    onSubmit={form.submit}
                    isSubmitLoading={isSubmitting}
                    onChangeStep={customOnChangeStep}
                    submitLabel='Save'
                />
            </SlideoutFooter>
        </Slideout>
    );
};
