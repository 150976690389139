import { useMutation, useQueryClient } from '@tanstack/react-query';
import { countBy } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import { BulkStorageOrderItemDto, bulkStorageOrdersCacheKey, bulkStorageOrderService } from '@hofy/api-admin';
import { ItemGrade } from '@hofy/api-shared';
import {
    FormFieldRecord,
    isRequired,
    useForm,
    useFormArrayField,
    useToast,
    validateArrayField,
    validator,
} from '@hofy/ui';

import {
    AssignBulkStorageOrderItemFormData,
    AssignBulkStorageOrderItemValidData,
    AssignBulkStorageOrderItemValidFormData,
    AssignedBulkStorageItemFormData,
    emptyAssignBulkStorageOrderItem,
    emptyAssignBulkStorageOrderItemDetail,
} from './types/AssignBulkStorageOrderItemFormData';

interface UseAssignBulkStorageOrderItemsProps {
    onSuccess(): void;
    bulkStorageOrderItem: BulkStorageOrderItemDto | null;
}

export const useAssignBulkStorageOrderItems = ({
    onSuccess,
    bulkStorageOrderItem,
}: UseAssignBulkStorageOrderItemsProps) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const duplicateItemCodes = useRef<Set<string>>(new Set());
    const duplicateSerialNumbers = useRef<Set<string>>(new Set());

    const mutation = useMutation({
        mutationFn: (data: AssignBulkStorageOrderItemValidData) =>
            bulkStorageOrderService.assignBulkStorageOrderItem(data.bulkStorageOrderItemId, {
                itemIds: data.items.map(item => item.itemDto.id),
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [bulkStorageOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Bulk storage order items received',
            });
            form.setValues({
                items: [],
            });
            onSuccess();
        },
    });

    const form = useForm<AssignBulkStorageOrderItemFormData, AssignBulkStorageOrderItemValidData>({
        initial: emptyAssignBulkStorageOrderItem(),
        initialDeps: [bulkStorageOrderItem],
        onSubmit: data => {
            if (data.items.length > 1) {
                const lastItem = data.items[data.items.length - 1];
                if (!lastItem.itemCode) {
                    // last array item is empty and may be skipped
                    data.items = data.items.slice(0, -1);
                }
            }
            mutation.mutate(data);
        },
        validate: validator<AssignBulkStorageOrderItemFormData>({
            items: validateArrayField<AssignBulkStorageOrderItemValidData, 'items'>({
                selfRules: isRequired('At least one item is required'),
                fieldsValidator: validator<AssignBulkStorageOrderItemValidFormData>({
                    itemCode: [
                        isRequired('Item code is required'),
                        value => {
                            if (duplicateItemCodes.current.has(value)) {
                                return 'Item code is duplicated';
                            }
                        },
                        (_, field) => {
                            if (!field.itemCode) {
                                return;
                            }
                            if (!field.itemDto) {
                                return 'Item code not found';
                            }
                            if (field.itemDto.contractDetails) {
                                return 'Item is already assigned to a contract';
                            }
                            if (field.itemDto.grade !== ItemGrade.New) {
                                return 'Item is not new';
                            }
                            if (field.itemDto.variant.id !== bulkStorageOrderItem?.variant.id) {
                                return 'Item does not match the order variant';
                            }
                            if (!field.itemDto.location.warehouse?.id) {
                                return 'Item is not at the warehouse';
                            }
                        },
                    ],
                }),
                shouldSkip(value, field) {
                    // skip the validation of the last field if it's empty (it
                    // is omitted in onSubmit above)
                    return (
                        value.length > 1 &&
                        value.indexOf(field) === value.length - 1 &&
                        !field.itemCode &&
                        !field.serialNumber
                    );
                },
            }),
        }),
        validateDeps: [bulkStorageOrderItem, duplicateSerialNumbers],
    });

    useEffect(() => {
        const { items } = form.fields;

        const itemCodes = countBy(items.value.map(item => item.itemCode));
        const newDupicateItemCodes = new Set(
            Object.entries(itemCodes)
                .filter(([, count]) => count > 1)
                .map(([key]) => key),
        );
        if (newDupicateItemCodes !== duplicateItemCodes.current) {
            duplicateItemCodes.current = newDupicateItemCodes;
        }
    }, [form]);

    const [scansLeft, setScansLeft] = useState<number>(0);

    const isItemEmpty = (item: FormFieldRecord<AssignedBulkStorageItemFormData>) => {
        return !item.itemCode?.value;
    };

    const items = useFormArrayField(form.fields.items, emptyAssignBulkStorageOrderItemDetail);

    // automatically add a new item row if the last item is not empty up to limit
    useEffect(() => {
        const lastItem = items.fields[items.fields.length - 1];
        if (!lastItem || (!isItemEmpty(lastItem.api) && items.fields.length < scansLeft)) {
            items.add();
        }

        items.fields.forEach((item, index) => {
            if (index + 1 === items.fields.length) {
                return;
            }
            if (isItemEmpty(item.api)) {
                items.remove(item.key);
            }
        });
    }, [items, scansLeft]);

    return {
        form,
        items,
        setScansLeft,
        isItemEmpty,
        isLoadingMutation: mutation.isPending,
        isErrorMutation: mutation.isError,
    };
};
