import { Country } from '@hofy/global';
import { useFilters } from '@hofy/ui';

import { useCountryFilterDefinition } from '../filters/useCountryFilterDefinition';

export interface BillingEntityFilters {
    countries: Country[];
}

const emptyBillingEntityFilters: BillingEntityFilters = {
    countries: [],
};

export const useBillingEntityFilter = (initialFilters?: Partial<BillingEntityFilters>) => {
    const countries = useCountryFilterDefinition();
    return useFilters<BillingEntityFilters>(
        {
            countries,
        },
        {
            ...emptyBillingEntityFilters,
            ...initialFilters,
        },
    );
};
