import { addonsService } from '@hofy/api-admin';
import { AddonTarget, AddonType } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

import { useAddonForm } from './useAddonForm';

export const useCreateAddon = (onSuccess: () => void) => {
    const { showToast } = useToast();

    return useAddonForm({
        initial: {
            name: 'New addon',
            publicName: 'New addon',
            services: [],
            type: AddonType.Service,
            target: AddonTarget.Asset,
            isPublic: false,
            isPremium: false,
            constraintDependencies: [],
            pricingDependencies: [],
            discountDependencies: [],
            constraints: [],
            pricings: [],
            discounts: [],
            pools: [],
        },
        onSubmit: payload => addonsService.createAddon(payload),
        onSuccess: () => {
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Addon successfully created',
            });
        },
    });
};
