import { RefObject, useCallback, useEffect, useState } from 'react';
import { useMountedState } from 'react-use';

export const useImageLoad = (r: RefObject<HTMLImageElement>, url: string | null | undefined) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const isMounted = useMountedState();

    const onLoad = useCallback(() => {
        if (isMounted()) {
            setIsLoaded(true);
        }
    }, [r.current, url]);

    const onError = useCallback(() => {
        if (isMounted()) {
            setIsError(true);
        }
    }, [r.current, url]);

    useEffect(() => {
        setIsError(false);
        setIsLoaded(false);
    }, [url]);

    useEffect(() => {
        if (!r.current || !url) {
            return;
        }

        if (r.current.complete) {
            setIsLoaded(r.current.naturalHeight !== 0);
            setIsError(r.current.naturalHeight === 0);
            return;
        }

        r.current.addEventListener('load', onLoad);
        r.current.addEventListener('error', onError);
        return () => {
            if (!r.current) {
                return;
            }
            r.current.removeEventListener('load', onLoad);
            r.current.removeEventListener('error', onError);
        };
    }, [r.current, url]);

    return {
        isError,
        isLoaded,
    };
};
