import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { bulkStorageOrderService } from '../service/bulkStorageOrderService';
import { bulkStorageOrdersCacheKey } from './cacheKey';

export const useBulkStorageOrderQuery = (id: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [bulkStorageOrdersCacheKey, 'bulk-storage-order', id],

        queryFn: () => bulkStorageOrderService.getBulkStorageOrder(id),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
