import { useMutation } from '@tanstack/react-query';

import { issueService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useDownloadIssueDocument = () => {
    const mutation = useMutation({
        mutationFn: (p: { issueId: UUID; fileId: UUID }) =>
            issueService.getIssueDocument(p.issueId, p.fileId),
    });

    const download = (issueId: UUID, fileId: UUID) => {
        mutation.mutate({ issueId, fileId });
    };

    return {
        download,
        isDownloading: mutation.isPending,
        isDownloadError: mutation.isError,
    };
};
