import { Price } from '@hofy/global';

export interface AddSeatsToOrganizationContractPayload {
    seats: number;
    price: Price;
    isDefault: boolean;
}

export const initialAddSeatsPayload = (initialPrice: Price): AddSeatsToOrganizationContractPayload => ({
    seats: 0,
    price: initialPrice,
    isDefault: false,
});
