import { BulkStorageOrdersFilter, emptyBulkStorageOrdersFilter } from '@hofy/api-admin';
import { BulkStorageOrderStatus } from '@hofy/api-shared';
import { useFilters } from '@hofy/ui';

import { useMultiEnumFilterDefinition } from '../filters/useMultiEnumFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useWarehousesFilterDefinition } from '../warehouses/filters/useWarehousesFilterDefinition';
import { useTrBulkStorageOrderStatus } from './useTrBulkStorageOrderStatus';

export const useBulkStorageOrdersFilters = () => {
    const search = useSearchFilterDefinition();
    const warehouses = useWarehousesFilterDefinition('Location');
    const statuses = useMultiEnumFilterDefinition({
        statusEnum: BulkStorageOrderStatus,
        toLabel: useTrBulkStorageOrderStatus(),
    });

    return useFilters<BulkStorageOrdersFilter>(
        {
            search,
            warehouses,
            statuses,
        },
        emptyBulkStorageOrdersFilter,
    );
};
