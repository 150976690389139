import { useMutation, useQueryClient } from '@tanstack/react-query';

import { subscriptionCacheKey, subscriptionService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useEndSubscription = (organizationId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (subscriptionId: UUID) =>
            subscriptionService.endSubscription(organizationId, subscriptionId),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: [subscriptionCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: 'Subscription ended.',
            });
        },
    });

    return {
        endSubscription: (subscriptionId: UUID) => mutation.mutate(subscriptionId),
        isLoading: mutation.isPending,
    };
};
