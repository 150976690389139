import React, { FC } from 'react';

import { useBulkStorageOrderQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ErrorStatePlaceholder } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { BulkStorageOrderDetailsContent } from './BulkStorageOrderDetailsContent';

interface BulkStorageOrderDetailsSlideoutProps {
    orderId: UUID;
    onClose(): void;
}

export const BulkStorageOrderDetailsSlideout: FC<BulkStorageOrderDetailsSlideoutProps> = ({
    orderId,
    onClose,
}) => {
    const { data: order, isLoading, isError } = useBulkStorageOrderQuery(orderId);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (!order || isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Slideout width={1000} onClose={onClose} slideoutId='bulk-storage-order'>
            <SlideoutHeader title={`Bulk storage order ${order.publicId}`} />
            <SlideoutContent>
                <BulkStorageOrderDetailsContent order={order} />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};
