import { useMutation, useQueryClient } from '@tanstack/react-query';

import { bulkStorageOrdersCacheKey, bulkStorageOrderService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useAcceptBulkStorageOrder = (bulkStorageOrderId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => bulkStorageOrderService.acceptBulkStorageOrder(bulkStorageOrderId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [bulkStorageOrdersCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Bulk storage order accepted',
            });
        },
    });

    return {
        mutate: mutation.mutate,
    };
};
