export const organizationDeelUpdateI18n = {
    'organization-page.deel-update.modal.title': 'Update Deel data',
    'organization-page.deel-update.modal.confirm': 'Save',
    'organization-page.deel-update.modal.cancel': 'Cancel',
    'organization-page.deel-update.modal.form.deel-id': 'Deel Id',
    'organization-page.deel-update.modal.form.it-ready': 'Deel IT ready',
    'organization-page.deel-update.modal.hris-title': 'Organization is connected to a HR system',
    'organization-page.deel-update.modal.hris-description':
        'Please disconnect it before linking the organization to Deel IT',
};
