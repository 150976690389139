import React, { FC } from 'react';

import { BulkStorageOrderDto } from '@hofy/api-admin';
import { Box, FormGridRow, LabeledText } from '@hofy/ui';

interface BulkStorageOrderDetailsProps {
    order: BulkStorageOrderDto;
}

export const BulkStorageOrderDetails: FC<BulkStorageOrderDetailsProps> = ({ order }) => {
    return (
        <Box>
            <FormGridRow columns={4} marginVertical={20}>
                <LabeledText label='Organization' content={order.organization.name} />
                <LabeledText label='Warehouse' content={order.toWarehouse.name} />
            </FormGridRow>
        </Box>
    );
};
