import { forcedNetsuiteSyncQuery } from '@hofy/api-shared';
import { PageRequest, PageResponse, regionToCountries, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { ListProductFilters } from './types/ListProductFilters';
import { ProductDetailsDto } from './types/ProductDetailsDto';
import { ProductDto } from './types/ProductDto';
import { ProductPayload } from './types/ProductPayload';
import { VariantRefDto } from './types/VariantRefDto';
import {
    AdminProductPricingDetailsDto,
    AdminProductPricingDetailsPayload,
    VariantWithProductAndPriceQuery,
    VariantWithProductDto,
} from './types/VariantWithProductDto';

class ProductService {
    public listProducts = async (
        pagination: PageRequest,
        options?: Partial<ListProductFilters>,
    ): Promise<PageResponse<ProductDto>> => {
        return restClient.getJson<PageResponse<ProductDto>>(
            stringifyUrl({
                url: '/api/admin/products',
                query: {
                    ...options,
                    regions: options?.regions?.flatMap(regionToCountries),
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                },
            }),
        );
    };

    public getProducts = async (): Promise<ProductDto[]> => {
        const response = await restClient.getJson<{
            products: ProductDto[];
        }>(`/api/admin/products/all`);
        return response.products;
    };

    public getVariantsWithProductAndPrice = async (
        query: VariantWithProductAndPriceQuery,
    ): Promise<VariantWithProductDto[]> => {
        return restClient.getJson<VariantWithProductDto[]>(
            stringifyUrl({ url: '/api/admin/products/variants', query: { ...query } }),
        );
    };

    public getProductPricingDetails = async (
        payload: AdminProductPricingDetailsPayload,
    ): Promise<AdminProductPricingDetailsDto> => {
        return restClient.postJson<AdminProductPricingDetailsDto>(`/api/admin/products/pricing`, payload);
    };

    public getVariantRefs = async (search: string): Promise<VariantRefDto[]> => {
        return await restClient.getJson<VariantRefDto[]>(
            stringifyUrl({
                url: '/api/admin/products/variants/refs',
                query: {
                    search,
                },
            }),
        );
    };

    public getProduct = async (id: UUID): Promise<ProductDetailsDto> => {
        return restClient.getJson<ProductDetailsDto>(`/api/admin/products/${id}`);
    };

    public createProduct = (payload: ProductPayload): Promise<number> => {
        return restClient.postJson<{ id: number }>(`/api/admin/products`, payload).then(r => r.id);
    };

    public updateProduct = (id: UUID, payload: ProductPayload): Promise<void> => {
        return restClient.put(`/api/admin/products/${id}`, payload);
    };

    public syncToNetsuite = async (id: UUID) => {
        return restClient.post(`/api/admin/products/${id}/sync-to-netsuite`);
    };

    public syncAllToNetsuite = async () => {
        return restClient.post(
            stringifyUrl({
                url: '/api/admin/products/sync-to-netsuite',
                query: { ...forcedNetsuiteSyncQuery },
            }),
        );
    };
}

export const productsService = new ProductService();
