export const betaFeatureI18n = {
    'beta-feature.reuse-contract-on-basket': 'Show reusable contract on basket',
    'beta-feature.asset-tracking': 'Asset Tracking',
    'beta-feature.asset-upload': 'Asset Upload',
    'beta-feature.self-serve-storage': 'Self Serve Storage',
    'beta-feature.item-refresh': 'Item refresh',
    'beta-feature.asset-fleet-host-data': 'Asset Fleet Host Data',
    'beta-feature.hofy-logistics-for-organization-warehouses':
        'Hofy logistics for organization storage locations',
    'beta-feature.slack': 'Slack integration',
    'beta-feature.zendesk-chat': 'Zendesk chat',
    'beta-feature.asset-properties': 'Asset properties',
    'beta-feature.new-asset-tracking-listing': 'New asset tracking listing',
    'beta-feature.organization-signup': 'Organization signup',
    'beta-feature.public-api': 'Public API',
    'beta-feature.fleet-services': 'Fleet services',
    'beta-feature.automatic-shipment-tracking': 'Automatic track shipment status (global)',
    'beta-feature.automatic-shipment-status-update': 'Automatic update shipment status (global)',
    'beta-feature.asset-anomalies': 'Asset anomalies',
    'beta-feature.deel-invoicing': 'Deel invoicing',
};
