import { useMutation } from '@tanstack/react-query';

import {
    dataSeedService,
    emptySeedRandomOrganizationWarehousesPayload,
    SeedRandomOrganizationWarehousesPayload,
} from '@hofy/api-admin';
import { isGreaterThan, isRequired, isValidUuid, useForm, useToast, validator } from '@hofy/ui';

export const useSeedOrganizationWarehouse = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedRandomOrganizationWarehouse,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Organization warehouses generated',
            });
        },
    });

    const form = useForm<SeedRandomOrganizationWarehousesPayload>({
        initial: emptySeedRandomOrganizationWarehousesPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedRandomOrganizationWarehousesPayload>({
            organizationId: [
                isRequired('Organization is required'),
                isValidUuid('Organization must be valid'),
            ],
            count: [isRequired('Number of warhouses is required'), isGreaterThan(0, 'Count must be valid')],
        }),
    });

    return { form, isLoading: mutation.isPending };
};
