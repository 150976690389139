import React, { FC, useState } from 'react';

import { BulkStorageOrderDto } from '@hofy/api-admin';
import { BulkStorageOrderStatus, Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { ConfirmModal, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { useAcceptBulkStorageOrder } from '../../../../store/bulkStorageOrders/useAcceptBulkStorageOrder';
import { useCancelBulkStorageOrder } from '../../../../store/bulkStorageOrders/useCancelBulkStorageOrder';

interface BulkStorageOrderActionMenuProps {
    onAssignOrder(id: UUID): void;
    order: BulkStorageOrderDto;
}
enum ModalType {
    CancelBulkStorageOrder,
    AcceptBulkStorageOrder,
}

export const BulkStorageOrderActionMenu: FC<BulkStorageOrderActionMenuProps> = ({ onAssignOrder, order }) => {
    const [modal, setModal] = useState<ModalType>();
    const closeModal = () => setModal(undefined);

    const { mutate: onCancelBulkStorageOrder } = useCancelBulkStorageOrder(order.id);
    const { mutate: onAcceptBulkStorageOrder } = useAcceptBulkStorageOrder(order.id);
    const { hasPermission } = usePermission();
    if (!hasPermission(Permission.AdminBulkStorageOrdersUpdate)) {
        return null;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        label: 'Accept and activate contracts',
                        icon: SvgIcon.Package,
                        action: () => setModal(ModalType.AcceptBulkStorageOrder),
                        visible: order.status === BulkStorageOrderStatus.Pending,
                    },
                    {
                        label: 'Receive',
                        icon: SvgIcon.Package,
                        action: () => onAssignOrder(order.id),
                        visible: order.isReceiveAllowed,
                    },
                    {
                        label: 'Cancel',
                        icon: SvgIcon.Trash,
                        action: () => setModal(ModalType.CancelBulkStorageOrder),
                        visible: order.isCancelAllowed,
                    },
                ]}
            />

            {modal === ModalType.CancelBulkStorageOrder && (
                <ConfirmModal
                    keyPrefix='cancel-bulk-storage-order-modal'
                    onConfirm={onCancelBulkStorageOrder}
                    onClose={closeModal}
                    action='destructive'
                >
                    <Paragraph3 marginTop={10} color={Color.ContentPrimary}>
                        Bulk storage order: <Span bold>{`#${order.publicId}`}</Span>
                    </Paragraph3>
                </ConfirmModal>
            )}
            {modal === ModalType.AcceptBulkStorageOrder && (
                <ConfirmModal
                    keyPrefix='accept-bulk-storage-order-modal'
                    onConfirm={onAcceptBulkStorageOrder}
                    onClose={closeModal}
                >
                    <Paragraph3 marginTop={10} color={Color.ContentPrimary}>
                        Bulk storage order: <Span bold>{`#${order.publicId}`}</Span>
                    </Paragraph3>
                </ConfirmModal>
            )}
        </>
    );
};
