export const taxIdLabelI18n = {
    'tax-id-label.BR': 'CPF',
    'tax-id-label.AR': 'DNI',
    'tax-id-label.IL': 'Mispar Zehut',
    'tax-id-label.PE': 'DNI',
    'tax-id-label.PK': 'CNIC',
    'tax-id-label.TR': 'T.C. Kimlik No.',
    'tax-id-label.CO': 'NIT',
    'tax-id-label.CL': 'RUT',
    'tax-id-label.PY': 'C.I.',
    'tax-id-label.CR': 'C.I.',
    'tax-id-label.SV': 'DUI',
    'tax-id-label.DO': 'RNC',
    'tax-id-label.HN': 'TI',
    'tax-id-label.GY': 'Guayana ID No.',
    'tax-id-label.BO': 'Cedula de Identidad',
    'tax-id-label.EC': 'Cedula de Identidad',
    'tax-id-label.UY': 'Cedula de Identidad',
    'tax-id-label.VE': 'Cedula de Identidad',
    'tax-id-label.ZA': 'South Africa ID No.',
    'tax-id-label.GE': 'Tax Identification Number (TIN)',
    'tax-id-label.KZ': 'Individual Identification Number (IIN)',
    'tax-id-label.SR': 'Identiteitskaart',
    'tax-id-label.LK': 'NIC Number',
    'tax-id-label.CN': '18 digit China mainland ID',
    'tax-id-label.PA': 'Cedula de Identidad',
    'tax-id-label.NI': 'Cedula de Identidad',
    'tax-id-label.GT': 'Cedula de Identidad',
    'tax-id-label.ID': 'National Identity Card',
    'tax-id-label.MD': 'IDNP',
    'tax-id-label.none': 'Tax ID',
};
