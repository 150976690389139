export enum OrganizationError {
    TeamLimitReached = 'team_limit_reached',
    BillingEntityLimitReached = 'billing_entity_limit_reached',
    IncorrectPlatformTier = 'incorrect_platform_tier',
    UpgradeTrialOrganization = 'cannot_upgrade_trial_organization',
    InvalidBundlingAndTier = 'invalid_bundling_and_tier_combination',
    InvalidPaymentSchemaAndTier = 'invalid_payment_schema_and_tier_combination',
    BillingEntityHasInvoiceEntries = 'billing_entity_has_invoice_entries',
    CannotUpdateBillingEntityCurrency = 'cannot_update_billing_entity_currency',
    BillingEntityCurrencyUpdateInvalidArgument = 'invalid_argument_for_billing_entity_currency_update',
    InvoiceCountriesUsedInOtherEntity = 'invoice_countries_used_in_other_entity',
    TwoSync = 'two_sync_failed',
    MissingDeelOrganizationLink = 'missing_deel_organization_link',
    AccountingSync = 'accounting_sync_failed',
    InvalidBillingEntityAccountingData = 'invalid_billing_entity_accounting_data',
    OrganizationWithoutDefaultEntity = 'organization_without_default_billing_entity',
    BillingEntityUpdateNonFreeTier = 'update_non_free_tier_organization_billing_entity',
    SubscriptionAlreadyExists = 'subscription_already_exists',
    SubscriptionAlreadyInvoiced = 'subscription_already_invoiced',
    InvalidSubscriptionBillingFrequency = 'invalid_subscription_billing_frequency',
    SubscriptionNotActive = 'subscription_not_active',
    InvalidSubscriptionStartDate = 'invalid_subscription_start_date',
    SubscriptionNotInvoiced = 'subscription_not_invoiced',
    InvalidSubscriptionCurrency = 'subscription_currency_not_valid',
    SubscriptionNotFound = 'subscription_not_found',
    SubscriptionAlreadyStarted = 'subscription_already_started',
    InvalidActionOnSubscriptionType = 'invalid_action_on_subscription_type',
    InvalidBillingFrequency = 'invalid_billing_frequency',
    OrganizationModelChangeWithRequests = 'organization_model_change_with_request',
    OrganizationModelChangeWithDiscounts = 'not_allowed_to_change_model_with_active_discounts',
    OrganizationAlreadyLinked = 'organization_already_linked_error',
    OrganizationAlreadyOnPerSeat = 'organization_already_on_per_seat',
    OrganizationNotUnbundled = 'organization_not_unbundled',
}
