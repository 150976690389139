import { useMutation } from '@tanstack/react-query';

import { bulkStorageOrderService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useDownloadBulkStorageOrderDocument = () => {
    const mutation = useMutation({
        mutationFn: (p: { bulkStorageOrderId: UUID; fileId: UUID }) =>
            bulkStorageOrderService.downloadBulkStorageOrderDocument(p.bulkStorageOrderId, p.fileId),
    });
    const download = (bulkStorageOrderId: UUID, fileId: UUID) => {
        mutation.mutate({ bulkStorageOrderId, fileId });
    };

    return {
        download,
        isDownloading: mutation.isPending,
        isDownloadError: mutation.isError,
    };
};
