import { ColorDye, getEnumValues } from '@hofy/global';

export enum SeatAssignmentStatus {
    PendingApproval = 'pending_approval',
    Declined = 'declined',
    Active = 'active',
    Inactive = 'inactive',
    Expired = 'expired',
}

export const seatAssignmentStatusColor: Record<SeatAssignmentStatus, ColorDye> = {
    [SeatAssignmentStatus.PendingApproval]: 'orange',
    [SeatAssignmentStatus.Declined]: 'red',
    [SeatAssignmentStatus.Active]: 'green',
    [SeatAssignmentStatus.Inactive]: 'grey',
    [SeatAssignmentStatus.Expired]: 'grey',
};

export const allSeatAssignmentStatuses = getEnumValues<SeatAssignmentStatus>(SeatAssignmentStatus);
