import { useQuery } from '@tanstack/react-query';

import { AdminProductPricingDetailsPayload, productCacheKey, productsService } from '@hofy/api-admin';

export const useProductPricing = (payload: AdminProductPricingDetailsPayload) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [
            productCacheKey,
            payload.organizationId,
            payload.variantId,
            payload.teamId,
            payload.country,
            payload.contractSettings,
        ],
        queryFn: () => productsService.getProductPricingDetails(payload),
    });
    return {
        isLoading,
        isError,
        data,
    };
};
