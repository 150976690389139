import React, { FC, useMemo, useState } from 'react';

import { useOrganizationContract } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Box, Button, Modals, PageHeader, Skeleton, SvgIcon, Tab, Tabs } from '@hofy/ui';

import {
    allOrganizationContractTabs,
    OrganizationContractTab,
} from '../../../store/contracts/types/OrganizationContractTab';
import { useTrOrganizationContractTab } from '../../../store/organizationContracts/useTrOrganizationContractTab';
import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { AddSeatsModal } from './components/AddSeatsModal';
import { OrganizationContractDetails } from './components/OrganizationContractDetails';
import { SeatAssignmentTable } from './components/SeatAssignmentTable';
import { SubscriptionTable } from './components/SubscriptionTable';

interface OrganizationContractDetailsOverlayProps {
    organizationId: UUID;
    organizationContractId: UUID;
    organizationContractTab: OrganizationContractTab;
    onChangeTab(tab: OrganizationContractTab): void;
    getEnrollUsersLink(id: UUID): string;
    onBackToOrganizationContract(): void;
}

export const OrganizationContractDetailsOverlay: FC<OrganizationContractDetailsOverlayProps> = ({
    organizationId,
    organizationContractId,
    organizationContractTab,
    onChangeTab,
    getEnrollUsersLink,
    onBackToOrganizationContract,
}) => {
    const trTab = useTrOrganizationContractTab();
    const { data: organization, isLoading: isLoadingOrganization } =
        useOrganizationDetailsQuery(organizationId);
    const { organizationContract, isLoading: isLoadingContract } = useOrganizationContract(
        organizationId,
        organizationContractId,
    );

    const { hasPermission } = usePermission();
    const [isAddSeatsModalOpen, setIsAddSeatsModalOpen] = useState(false);

    const shouldDisableAddSeatsModal = useMemo(() => {
        return (
            !hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) ||
            isLoadingContract ||
            !organizationContract
        );
    }, [hasPermission, isLoadingContract, organizationContract]);

    if (isLoadingOrganization || isLoadingContract) {
        return (
            <Overlay column flex='auto' bg={Color.BackgroundDefault}>
                <Skeleton />
            </Overlay>
        );
    }

    const renderContent = () => {
        switch (organizationContractTab) {
            case OrganizationContractTab.Details:
                return <OrganizationContractDetails organizationContract={organizationContract!} />;
            case OrganizationContractTab.Seats:
                return (
                    <SeatAssignmentTable
                        organizationId={organizationId}
                        organizationContractId={organizationContract!.id}
                    />
                );
            case OrganizationContractTab.Subscriptions:
                return (
                    <SubscriptionTable
                        organizationId={organizationId}
                        organizationContractId={organizationContract!.id}
                    />
                );
        }
    };

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={organization!.name}
                rightSlot={
                    <>
                        {organizationContractTab === OrganizationContractTab.Details && (
                            <Button
                                type='primary'
                                label='Add Seats'
                                disabled={shouldDisableAddSeatsModal}
                                leftIcon={SvgIcon.Add}
                                onClick={() => setIsAddSeatsModalOpen(true)}
                            />
                        )}
                        {organizationContractTab === OrganizationContractTab.Seats && (
                            <Button
                                type='primary'
                                label='Enroll users'
                                to={getEnrollUsersLink(organizationContractId)}
                                disabled={!hasPermission(Permission.AdminOrganizationUpdateFinancialSettings)}
                                leftIcon={SvgIcon.Add}
                            />
                        )}
                        <Button
                            type='secondary'
                            label='Organization contracts'
                            leftIcon={SvgIcon.File02}
                            onClick={() => onBackToOrganizationContract()}
                        />
                    </>
                }
                tabsSlot={
                    <Tabs active={organizationContractTab} onChange={onChangeTab}>
                        {allOrganizationContractTabs.map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
            <Modals>
                {hasPermission(Permission.AdminOrganizationUpdateFinancialSettings) &&
                    isAddSeatsModalOpen && (
                        <AddSeatsModal
                            organizationId={organizationId}
                            organizationContractId={organizationContractId}
                            monthlySeatPrice={organizationContract!.monthlySeatPrice}
                            onClose={() => setIsAddSeatsModalOpen(false)}
                        />
                    )}
            </Modals>
        </Overlay>
    );
};
