export const organizationStatusUpdateI18n = {
    'organization-page.status-update.title': 'Update organization status',
    'organization-page.status-update.subtitle': 'This operation will update the organization status.',
    'organization-page.status-update.modal.confirm': 'Save',
    'organization-page.status-update.modal.cancel': 'Cancel',
    'organization-page.status-update.modal.trial-end': 'Trial end date',
    'organization-page.status-update.modal.set-org-message': 'Set organization message',
    'organization-page.status-update.modal.set-org-message-helper':
        'The message that will be displayed as a banner on top of every page',
    'organization-page.status-update.modal.subscription-warning.title': 'Subscription invoice warning',
    'organization-page.status-update.modal.subscription-warning.description':
        'Subscription start date is in the past or subscription is activated in the last day of month. ' +
        'Please check the subscription record and adjust start date or manually create invoice entries, if needed. ' +
        'Current subscription will not generate invoices for previous billing periods.',
    'organization-page.create-organization-contract.platform-fee-alert-title':
        'Active platform fee subscriptions',
    'organization-page.create-organization-contract.platform-fee-alert-description':
        'This organization has active platform fee subscriptions. Please end all platform fee subscriptions before creating a new IT seat organization contract. Failure to do so will result in the customer being charged for both platform and IT seat subscriptions.',
};
