import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { Spinner } from '@hofy/ui';

import { useSimulateTaxComputation } from '../../../../store/invoiceEntries/useSimulateTaxComputation';

export const InvoiceEntriesPageMenu: FC = () => {
    const { hasPermission } = usePermission();
    const { simulateTaxComputation, isLoading } = useSimulateTaxComputation();

    if (isLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: simulateTaxComputation,
                    label: 'Simulate tax computation',
                    visible: hasPermission(Permission.AdminInvoicesEntriesUpdate),
                },
            ]}
        />
    );
};
