import {
    AddressDto,
    AssignmentDto,
    AuditableModelDto,
    ContractUnionDto,
    IssueType,
    OrganizationRefDto,
    ProductDto,
    UserStatus,
    VariantDto,
    ZendeskTicketStatusDto,
} from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';

export enum DocumentType {
    Image = 'image',
    Pdf = 'pdf',
    Other = 'other',
}

interface IssueDocument {
    fileName: string;
    type: DocumentType;
    id: UUID;
}

export interface IssueDetailsDto extends AuditableModelDto {
    publicId: string;
    description: string;
    resolvedOn: DateString | null;
    assignment: AssignmentDto;
    contract: ContractUnionDto | null;
    product: ProductDto;
    variant: VariantDto;
    user: IssueUserDto;
    organization: OrganizationRefDto;
    zendesk: ZendeskTicketStatusDto;
    isAbleToWork: boolean;
    issueType: IssueType;
    softwareDescription: string;
    issueDocuments: IssueDocument[];
}

export interface IssueUserDto {
    address: AddressDto;
    email: string;
    firstName: string;
    id: number;
    lastName: string;
    status: UserStatus;
}
