import { isEqual } from 'lodash-es';
import React, { FC, useState } from 'react';

import { Price, UUID, zeroPrice } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import {
    FormCheckbox,
    FormContainer,
    FormModal,
    FormNumberInput,
    FormPriceInput,
    LabeledCheckbox,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useAddSeats } from '../../../../store/organizationContracts/addSeats/useAddSeats';

interface AddSeatsModalProps {
    organizationId: UUID;
    organizationContractId: UUID;
    monthlySeatPrice: Price;
    onClose(): void;
}

export const AddSeatsModal: FC<AddSeatsModalProps> = ({
    organizationId,
    organizationContractId,
    monthlySeatPrice,
    onClose,
}) => {
    const [isOverridePrice, setIsOverridePrice] = useState(false);
    const { formatPrice } = usePrice();

    const handleOverridePriceChange = () => {
        if (isOverridePrice) {
            addSeatsForm.form.fields.price.setValue(monthlySeatPrice);
            addSeatsForm.form.fields.isDefault.setValue(false);
        }
        setIsOverridePrice(!isOverridePrice);
    };

    const addSeatsForm = useAddSeats(organizationId, organizationContractId, monthlySeatPrice, onClose);

    return (
        <FormModal
            onSubmit={addSeatsForm.form.submit}
            isLoading={addSeatsForm.isLoading}
            onClose={onClose}
            width={600}
        >
            <ModalHeader
                title='Add more seats'
                subtitle={`Additional seats will be charged at ${formatPrice(addSeatsForm.form.fields.price.value ?? monthlySeatPrice)} per month`}
            />
            <ModalContent>
                <FormContainer>
                    <FormNumberInput
                        label='Number of additional seats'
                        api={addSeatsForm.form.fields.seats}
                        isRequired
                    />
                    <LabeledCheckbox
                        label='Override seat price'
                        helperText='Setting a different price will create a new subscription with the specified price'
                        checked={isOverridePrice}
                        onChange={handleOverridePriceChange}
                        disabled={addSeatsForm.form.fields.seats.value === 0}
                    />
                    {isOverridePrice && (
                        <>
                            <FormPriceInput
                                api={addSeatsForm.form.fields.price}
                                label='New monthly seat price'
                                defaultCurrency={monthlySeatPrice.currency}
                                isRequired
                            />
                            <FormCheckbox
                                label='Set as default price for contract'
                                helperText='Use new price when more seats are added'
                                api={addSeatsForm.form.fields.isDefault}
                                disabled={
                                    addSeatsForm.form.fields.price.value === null ||
                                    isEqual(
                                        addSeatsForm.form.fields.price.value,
                                        zeroPrice(monthlySeatPrice.currency),
                                    ) ||
                                    isEqual(addSeatsForm.form.fields.price.value, monthlySeatPrice)
                                }
                            />
                        </>
                    )}
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <SubmitButton label='Confirm' />
            </ModalFooter>
        </FormModal>
    );
};
