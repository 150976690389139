export const invoiceEntryTypeI18n = {
    'invoice-entry-type.saas': 'Platform Fee',
    'invoice-entry-type.rental': 'Rental',
    'invoice-entry-type.laptop-config': 'Config',
    'invoice-entry-type.certified-data-erasure': 'Certified data erasure',
    'invoice-entry-type.mdm': 'MDM',
    'invoice-entry-type.app-resale': 'App resale',
    'invoice-entry-type.assembled-delivery': 'Assembled delivery',
    'invoice-entry-type.end-of-contract-purchase': 'EOC purchase',
    'invoice-entry-type.purchase': 'Purchase of new equipment',
    'invoice-entry-type.cancellation': 'Cancellation',
    'invoice-entry-type.welcome-pack': 'Welcome Pack',
    'invoice-entry-type.it-service-desk': 'IT service desk',
    'invoice-entry-type.credit-note': 'Credit note',
    'invoice-entry-type.other': 'Other',
    'invoice-entry-type.custom-sourcing': 'Custom sourcing',
    'invoice-entry-type.custom-shipping': 'Custom shipping',
    'invoice-entry-type.repair': 'Repair',
    'invoice-entry-type.repair-with-parts': 'Repair with parts',
    'invoice-entry-type.replacement': 'Replacement',
    'invoice-entry-type.accessory-replacement': 'Accessory replacement',
    'invoice-entry-type.loaner': 'Loaner',
    'invoice-entry-type.express-shipping': 'Express shipping',
    'invoice-entry-type.redistribution-fee': 'Redistribution',
    'invoice-entry-type.packaging': 'Packaging',
    'invoice-entry-type.single-item-fee': 'Single item fee',
    'invoice-entry-type.non-hofy-device-charge': 'Non Hofy device charge',
    'invoice-entry-type.store-and-reuse-fee': 'Store and reuse fee',
    'invoice-entry-type.extended-warranty': 'Extended warranty',
    'invoice-entry-type.shipping': 'Shipping',
    'invoice-entry-type.service-bundle': 'Service bundle',
    'invoice-entry-type.store-and-reuse-item-replacement-fee': 'Store and reuse item replacement fee',
    'invoice-entry-type.clearance': 'Clearance of used equipment',
    'invoice-entry-type.store-and-reuse-customer-owned-storage-fee':
        'Store and reuse customer-owned storage fee',
    'invoice-entry-type.store-and-reuse-customer-owned-fee': 'Store and reuse customer-owned fee',
    'invoice-entry-type.device-unlock-fee': 'Device unlock fee',
    'invoice-entry-type.byod-activation-fee': 'BYOD activation fee',
    'invoice-entry-type.byod-storage-fee': 'Bring your own device storage fee',
};
