import React, { FC } from 'react';

import { JobDto, jobIsCancellable } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import {
    AsyncButton,
    IdColumn,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    TwoLineSmallCell,
} from '@hofy/ui';

import { useCancelJob } from '../../store/jobs/useCancelJob';
import { JobProgressCell } from './components/JobProgressCell';
import { JobStatusChip } from './components/JobStatusChip';

interface JobsTableProps {
    jobs: JobDto[];
    infinityScroll: InfiniteScrollConfig;
}

export const JobsTable: FC<JobsTableProps> = ({ jobs, infinityScroll }) => {
    return (
        <InfinityScrollTable
            data={jobs}
            toKey={job => job.id}
            infinityScroll={infinityScroll}
            emptyContent={<Placeholder illustration={SvgIllustration.NotFound} title='No jobs' />}
            minWidth={1300}
            columns={[
                IdColumn<JobDto>(),
                {
                    id: 'name',
                    header: 'Name',
                    width: 250,
                    flexGrow: 1,
                    renderer: job => (
                        <TwoLineSmallCell
                            line1={`${job.name}`}
                            line2={job.activeTask ? `Processing: ${job.activeTask}` : ''}
                        />
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 0,
                    width: 150,
                    renderer: job => <JobStatusChip status={job.status} />,
                },
                {
                    id: 'progress',
                    header: 'Progress',
                    flexGrow: 1,
                    renderer: job => <JobProgressCell job={job} />,
                },
                {
                    id: 'startedAt',
                    header: 'Started at',
                    renderer: job => formatDateTime(job.startedAt),
                },
                {
                    id: 'startedAt',
                    header: 'Finished at',
                    renderer: job => formatDateTime(job.finishedAt),
                },
                {
                    id: 'elapsed',
                    header: 'Elapsed time',
                    renderer: job => job.duration || '--',
                },
                {
                    id: 'cancel',
                    header: '',
                    renderer: job => <CancelJobButton job={job} />,
                },
            ]}
        />
    );
};

interface CancelJobButtonProps {
    job: JobDto;
}

const CancelJobButton: FC<CancelJobButtonProps> = ({ job }) => {
    const { cancelJob, isLoading, isError } = useCancelJob();
    const { hasPermission } = usePermission();
    const canCancelJob = (job: JobDto) => jobIsCancellable(job) && hasPermission(Permission.AdminJobsCancel);

    return (
        <AsyncButton
            label='Cancel'
            type='plain'
            disabled={!canCancelJob(job)}
            onClick={() => cancelJob(job)}
            isError={isError}
            isLoading={isLoading}
        />
    );
};
