import { fromPairs } from 'lodash-es';
import { DateTime } from 'luxon';

import { ExtendedBillingEntityDto, InvoiceGroupDto, SubscriptionDto } from '@hofy/api-admin';
import {
    AcquisitionType,
    AdminRefDto,
    allPaymentSchemas,
    AuditableDto,
    BetaFeatureName,
    CategoryContractSettingsConfigDto,
    ContractExpirySettingsMapDto,
    OperatingSystem,
    OrganizationDiscountDto,
    OrganizationSize,
    OrganizationStatus,
    OrganizationType,
    PaymentSchema,
    PaymentType,
    PlatformTierConfigDto,
    PlatformTierConfigFeaturesMap,
    PublicRefDto,
    RentalTerm,
    ZendeskStatusDto,
} from '@hofy/api-shared';
import { Currency, DateString, DateTimeString, Percent, Price, UUID } from '@hofy/global';

export interface OrganizationDto extends PublicRefDto {
    name: string;
    partnerName: string | null;
    currency: Currency;
    status: OrganizationStatus;
    userCount: number;
    creditUtilisationPercentage: number;
    isDeel: boolean;
}

export interface OrganizationPaymentDiscountDto {
    id: UUID;
    precedence: number;
    discountPercent: Percent;
    rentalTerm: RentalTerm;
    paymentSchema: PaymentSchema;
    validStartOn: DateString | null;
    validEndOn: DateString | null;
}

export interface OrganizationTermAndConditionDto {
    id: UUID;
    name: string;
    url: string | null;
    startOn: DateString | null;
    currentlyActive: boolean;
    createdAt: DateTimeString;
}

export type PaymentDiscounts = Record<PaymentSchema, Record<RentalTerm, Percent>>;

export interface OrganizationDetailsDto extends AuditableDto, PublicRefDto {
    name: string;
    isPartner: boolean;
    type: OrganizationType;
    partnerName: string | null;
    size: OrganizationSize | null;
    logoUrl: string | null;
    currency: Currency;
    unbundlingEnabled: boolean;
    welcomePacksEnabled: boolean;
    collectionSurveySkipDeviceReset: boolean;
    dataErasureEnabled: boolean;
    deviceConfigRequired: OperatingSystem[];
    serviceDeskEnabled: boolean;
    mdmEnabled: boolean;
    dseEnabled: boolean;
    hostAgentEnabled: boolean;
    storeAndReuseManagedByHofy: boolean;
    status: OrganizationStatus;
    platformTierConfig: PlatformTierConfigDto;
    platformTierConfigFeatures: PlatformTierConfigFeaturesMap;
    betaFeatures: BetaFeatureName[] | null;
    bannerMessage: string | null;
    deviceResetInstructionsLink: string | null;
    trialEndOn: DateString | null;
    pricing: OrganizationPricingDto;
    defaultPricing: OrganizationPricingDto;
    billingEntities: ExtendedBillingEntityDto[];
    crediting: OrganizationCreditingDto;
    mdmCount: number | null;
    successAdminUser: AdminRefDto | null;
    salesAdminUser: AdminRefDto | null;
    allowedBillingFrequencies: PaymentSchema[];
    allowedRentalTerms: RentalTerm[];
    hasContracts: boolean;
    discounts: OrganizationDiscountDto[];
    paymentDiscounts: OrganizationPaymentDiscountDto[];
    defaultPaymentDiscounts: PaymentDiscounts;
    defaultContractSettings: CategoryContractSettingsConfigDto[];
    defaultPaymentType: PaymentType;
    storefrontFee: Percent;
    allowedAcquisitionTypes: AcquisitionType[];
    zendesk: ZendeskStatusDto;
    deel: DeelOrganizationDto;
    invoiceGroups: InvoiceGroupDto[];
    contractExpirySettings: ContractExpirySettingsMapDto;
    activeSubscription: SubscriptionDto | null;
}

interface DeelOrganizationDto {
    id: UUID | null;
    lastUpdatedAt: DateTimeString | null;
    itReady: boolean;
}

interface OrganizationPricingDto {
    laptopConfigFee: Price;
    mdmFee: Price;
    dataErasureFee: Price;

    byodGracePeriodInMonths: number;
    byodActivationFee: Price;
    byodStorageFee: Price;

    storeAndReuseGracePeriodInMonths: number;
    storeAndReuseRentalZone1ActivationFee: Price;
    storeAndReuseRentalLocalActivationFee: Price;
    storeAndReuseCustomerOwnedHubActivationFee: Price;
    storeAndReuseCustomerOwnedLocalActivationFee: Price;
    storeAndReuseCustomerOwnedStorageFee: Price;
}

interface OrganizationCreditingDto {
    creditLimit: Price | null;
    creditUsed: Price;
}

const isValidDiscount = (discount: OrganizationPaymentDiscountDto, now: DateTime) => {
    const from = discount.validStartOn === null || now > DateTime.fromISO(discount.validStartOn);
    const to = discount.validEndOn === null || now < DateTime.fromISO(discount.validEndOn);
    return from && to;
};

export const discountsToMap = (
    discounts: OrganizationPaymentDiscountDto[],
    now: DateTime,
): Record<PaymentSchema, Record<RentalTerm, Percent | null>> => {
    const result = fromPairs(
        allPaymentSchemas.map(paymentSchema => [
            paymentSchema,
            {
                [RentalTerm.Rental6Months]: null,
                [RentalTerm.Rental12Months]: null,
                [RentalTerm.Rental24Months]: null,
                [RentalTerm.Rental36Months]: null,
            },
        ]),
    ) as Record<PaymentSchema, Record<RentalTerm, Percent | null>>;
    discounts.forEach(d => {
        if (isValidDiscount(d, now)) {
            result[d.paymentSchema][d.rentalTerm] = d.discountPercent;
        }
    });
    return result;
};

export const organizationIsTrial = (o?: OrganizationDetailsDto) => o?.status === OrganizationStatus.Trial;
