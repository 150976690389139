export const orderErrorI18n = {
    'api-error.request-status.title': 'Current request status is invalid',
    'api-error.request-status.message': 'Cannot perform this operation',
    'api-error.credit-limit-exceeded.title': 'Credit limit exceeded',
    'api-error.credit-limit-exceeded.message': 'Cannot perform this operation',
    'api-error.none-approved-request-items.title': 'There are no approved request items',
    'api-error.none-approved-request-items.message': 'Cannot perform this operation',
    'api-error.suspended-organization.title': 'Organization is suspended',
    'api-error.suspended-organization.message': 'Cannot perform this operation',
    'api-error.trial-organization.title': 'Organization status is trial',
    'api-error.trial-organization.message': 'Cannot perform this operation',
    'api-error.invalid-product-availability.title': 'Invalid product availability',
    'api-error.invalid-product-availability.message': 'Cannot perform this operation',
    'api-error.basket-price-exceed-available-credit.title': 'Basket price exceeds available credit',
    'api-error.basket-price-exceed-available-credit.message': 'Cannot perform this operation',
    'api-error.country.title': 'Residence country and delivery country do not match',
    'api-error.country.message': 'Cannot perform this operation',
    'api-error.user-is-offboarded.title': 'User is offboarded',
    'api-error.user-is-offboarded.message': 'Cannot perform this operation',
    'api-error.mismatch-basket-payload-country-user-country.title':
        'Basket paylod country not equal to user country',
    'api-error.mismatch-basket-payload-country-user-country.message': 'Cannot perform this operation',
    'api-error.no-address.title': 'Empty address',
    'api-error.no-address.message': 'Cannot perform this operation',
    'api-error.express-delivery-unsupported-country.title': 'Express delivery unsupported country',
    'api-error.express-delivery-unsupported-country.message': 'Cannot perform this operation',
    'api-error.team-member-equip-limit-reached.title': 'Team member equip limit reached',
    'api-error.team-member-equip-limit-reached.message': 'Cannot perform this operation',
    'api-error.missing-variant.title': 'Missing variant',
    'api-error.missing-variant.message': 'Cannot perform this operation',
    'api-error.no-items-available-for-reuse.title': 'No items available for reuse',
    'api-error.no-items-available-for-reuse.message': 'Cannot perform this operation',
    'api-error.acquisition-type-not-allowed.title': 'Acquisition type not allowed',
    'api-error.acquisition-type-not-allowed.message': 'Cannot perform this operation',
    'api-error.rental-term-not-allowed.title': 'Rental term not allowed',
    'api-error.rental-term-not-allowed.message': 'Cannot perform this operation',
    'api-error.user-has-no-active-seat.title': 'User has no active seat in organization',
    'api-error.user-has-no-active-seat.message': 'Cannot perform this operation',
};
