import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { bulkStorageOrderService } from '../service/bulkStorageOrderService';
import { BulkStorageOrdersFilter } from './BulkStorageOrdersFilter';
import { bulkStorageOrdersCacheKey } from './cacheKey';

export const useBulkStorageOrders = (filters: BulkStorageOrdersFilter) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [bulkStorageOrdersCacheKey, filters],
        queryFn: param =>
            bulkStorageOrderService.listBulkStorageOrders(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const bulkStorageOrders = useMemo(() => {
        if (data) {
            return data.pages.flatMap(v => v.content);
        }
        return [];
    }, [data]);

    return {
        bulkStorageOrders,
        bulkStorageOrdersIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
