import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { ConfirmModal, Modals, Spinner } from '@hofy/ui';

import { useForceRecreateRejectedPlans } from '../../../store/repaymentPlan/useForceRecreateRejectedPlans';
import { useRecheckRepaymentPlan } from '../../../store/repaymentPlan/useRecheckRepaymentPlan';
import { useSyncRepaymentPlans } from '../../../store/repaymentPlan/useSyncRepaymentPlans';

export const RepaymentPlansMenu: FC = () => {
    const { hasPermission } = usePermission();

    const [syncPlansModal, setSyncPlansModal] = useState(false);
    const [recheckRepaymentPlansModal, setRecheckRepaymentPlansModal] = useState(false);
    const { syncPlans, isLoading: isSyncLoading } = useSyncRepaymentPlans();
    const { recheck, isLoading: isRecheckLoading } = useRecheckRepaymentPlan([]);
    const { forceRecreate, isLoading: isForceRecreateLoading } = useForceRecreateRejectedPlans();

    if (isSyncLoading || isRecheckLoading || isForceRecreateLoading) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        action: () => setSyncPlansModal(true),
                        label: 'Sync repayment plans',
                        visible: hasPermission(Permission.AdminRepaymentPlanManage),
                    },
                    {
                        action: () => setRecheckRepaymentPlansModal(true),
                        label: 'Recheck rejected statuses',
                        visible: hasPermission(Permission.AdminRepaymentPlanManage),
                    },
                    {
                        action: () => forceRecreate(),
                        label: 'Force re-create rejected plans',
                        visible: hasPermission(Permission.AdminRepaymentPlanManage),
                    },
                ]}
            />
            <Modals>
                {syncPlansModal && (
                    <ConfirmModal
                        keyPrefix='sync-two-modal'
                        onConfirm={syncPlans}
                        onClose={() => setSyncPlansModal(false)}
                    />
                )}{' '}
                {recheckRepaymentPlansModal && (
                    <ConfirmModal
                        keyPrefix='recheck-plans-modal'
                        onConfirm={recheck}
                        onClose={() => setRecheckRepaymentPlansModal(false)}
                    />
                )}
            </Modals>
        </>
    );
};
