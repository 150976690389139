import React, { FC, useMemo, useState } from 'react';

import { ContractDetailsDtoWithPurchase } from '@hofy/api-admin';
import {
    ContractType,
    ManagementContractType,
    PaymentType,
    Permission,
    PurchaseContractStatus,
    purchaseContractStatusToManagementContractStatus,
} from '@hofy/api-shared';
import { Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Box, Modals } from '@hofy/ui';

import { ContractTab, rentalContractTabs } from '../../../store/contracts/types/ContractTab';
import { AssignmentsTable } from '../../itemsPage/AssignmentsTable';
import { ActivateContractModal } from '../activateContractModal/ActivateContractModal';
import { AddonsTable } from './AddonsTable';
import { BillingEntityUpdateModal } from './components/BillingEntityUpdateModal';
import { CommonContractHeader } from './components/CommonContractHeader';
import { ContractInvoiceEntriesTab } from './ContractInvoiceEntriesTab';
import { ManagementContractsDetails } from './ManagementContractsDetails';
import { PurchaseContractDetailsTab } from './PurchaseContractDetailsTab';
import { RepaymentPlansTab } from './RepaymentPlansTab';
import { StorageAssignmentsTable } from './StorageAssignmentsTable';

interface PurchaseDetailsProps {
    tab: ContractTab;
    contract: ContractDetailsDtoWithPurchase;
    onChangeTab(t: ContractTab): void;
    onOpenAddInvoiceEntrySlideout(organizationId: UUID): void;
    onInvoiceEntryClick(entryId: UUID): void;
    onEdit(contractId: UUID): void;
}

export const PurchaseDetails: FC<PurchaseDetailsProps> = ({
    tab,
    contract,
    onChangeTab,
    onOpenAddInvoiceEntrySlideout,
    onInvoiceEntryClick,
    onEdit,
}) => {
    const { hasPermission } = usePermission();
    const addons = useMemo(() => {
        const serviceAddons =
            contract.managementContracts?.flatMap(contract =>
                contract.addons.map(addon => {
                    return {
                        ...addon,
                        contractType: ContractType.Management,
                        contract: contract,
                        status: contract.status,
                    };
                }),
            ) ?? [];
        return [
            ...contract.purchaseContract.addons.map(addon => {
                return {
                    ...addon,
                    contractType: ContractType.Purchase,
                    contract: contract.purchaseContract,
                    status: purchaseContractStatusToManagementContractStatus[
                        contract.purchaseContract.status
                    ],
                };
            }),
            ...serviceAddons,
        ];
    }, [contract]);
    const storageAssignments =
        contract.managementContracts?.flatMap(contract => contract.storageAssignments) ?? [];
    const [includeDeletedLines, setIncludeDeletedLines] = useState(false);
    const [showUpdateBillingEntityModal, setShowUpdateBillingEntityModal] = useState(false);
    const [showActivateContractModal, setShowActivateContractModal] = useState(false);

    const renderContent = () => {
        switch (tab) {
            case ContractTab.Details:
                return <PurchaseContractDetailsTab contract={contract} />;
            case ContractTab.InvoiceEntries:
                return (
                    <ContractInvoiceEntriesTab
                        includeDeleted={includeDeletedLines}
                        contractId={contract.id}
                        onRowClick={entry => onInvoiceEntryClick(entry.id)}
                    />
                );
            case ContractTab.Assignments:
                return (
                    <AssignmentsTable maxWidth={2000} assignments={contract.assignments} inSlideout={false} />
                );
            case ContractTab.ServicePackages:
                return (
                    <ManagementContractsDetails
                        contracts={contract.managementContracts.filter(
                            c => c.type === ManagementContractType.Addon,
                        )}
                    />
                );
            case ContractTab.StoreAndReuse:
                return (
                    <ManagementContractsDetails
                        contracts={contract.managementContracts.filter(
                            c => c.type === ManagementContractType.StoreAndReuse,
                        )}
                    />
                );
            case ContractTab.Addons:
                return <AddonsTable addons={addons} />;
            case ContractTab.RepaymentPlans:
                return <RepaymentPlansTab contractId={contract.id} />;
            case ContractTab.StorageAssignments:
                return <StorageAssignmentsTable storageAssignments={storageAssignments} />;
        }
    };

    const hasStatusAndPermission = (permission: Permission, ...status: PurchaseContractStatus[]) => {
        return hasPermission(permission) && status.includes(contract.purchaseContract.status);
    };
    const canEditContract =
        contract.paymentType === PaymentType.Deferred &&
        contract.purchaseContract.status === PurchaseContractStatus.Pending;

    const canActivateContract = hasStatusAndPermission(
        Permission.AdminContractsActivate,
        PurchaseContractStatus.Pending,
        PurchaseContractStatus.Cancelled,
    );

    const menuOptions = [
        {
            action: () => setShowActivateContractModal(true),
            label: 'Activate',
            visible: canActivateContract,
        },
        {
            action: () => onEdit(contract.id),
            label: 'Edit',
            visible: canEditContract,
        },
        {
            action: () => setShowUpdateBillingEntityModal(true),
            label: 'Update billing entity',
        },
    ];

    if (!contract) {
        return null;
    }
    const filteredTabs = rentalContractTabs.filter(
        tab => tab !== ContractTab.StorageAssignments || storageAssignments.length > 0,
    );
    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <CommonContractHeader
                contractTitle={`Purchase ${contract.publicId}`}
                contractId={contract.id}
                organizationId={contract.organization.id}
                tab={tab}
                tabs={filteredTabs}
                menuOptions={menuOptions}
                includeDeleted={includeDeletedLines}
                onChangeTab={onChangeTab}
                onSetIncludeDeleted={setIncludeDeletedLines}
                onOpenAddInvoiceEntrySlideout={onOpenAddInvoiceEntrySlideout}
            />
            <Box flex='auto' relative overflow='auto'>
                {renderContent()}
            </Box>
            <Modals>
                {showUpdateBillingEntityModal && (
                    <BillingEntityUpdateModal
                        contractId={contract.id}
                        organizationId={contract.organization.id}
                        currentBillingEntity={{
                            id: contract.purchaseContract.billingEntity.id,
                            currency: contract.purchaseContract.billingEntity.currency,
                        }}
                        onClose={() => setShowUpdateBillingEntityModal(false)}
                    />
                )}
                {showActivateContractModal && (
                    <ActivateContractModal
                        contract={contract}
                        onClose={() => setShowActivateContractModal(false)}
                    />
                )}
            </Modals>
        </Overlay>
    );
};
