import { ColorDye } from '@hofy/global';

export enum BulkStorageOrderStatus {
    Cancelled = 'cancelled',
    Pending = 'pending',
    Accepted = 'accepted',
    Processing = 'processing',
    Completed = 'completed',
}

export const bulkStorageOrderStatusColors: Record<BulkStorageOrderStatus, ColorDye> = {
    [BulkStorageOrderStatus.Cancelled]: 'red',
    [BulkStorageOrderStatus.Pending]: 'yellow',
    [BulkStorageOrderStatus.Accepted]: 'blue',
    [BulkStorageOrderStatus.Processing]: 'orange',
    [BulkStorageOrderStatus.Completed]: 'green',
};
