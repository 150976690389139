import React, { FC, useState } from 'react';

import { AuditableTable, ItemDto } from '@hofy/api-admin';
import {
    activeOrPendingRentalContractStatuses,
    ContractType,
    isCategoryConsumable,
    isValidRepairLocation,
    isValidRepairStatus,
    ItemStatus,
    Ownership,
    Permission,
    writtenOffStatus,
} from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { ConfirmModal, Modals, Paragraph3, Span, SvgIcon } from '@hofy/ui';

import { UpdateItemCodeModal } from '../../components/domain/items/UpdateItemCodeModal';
import { useAuditLogMenuOption } from '../../store/auditLogs/useAuditMenuOption';
import { useUnassignSubContract } from '../../store/items/useUnassignSubContract';
import { ChangeItemStatusModal } from './changeStatusModal/ChangeItemStatusModal';
import { CreateAssignmentModal } from './createAssignmentModal/CreateAssignmentModal';
import { UpdateItemModal } from './updateItemModal/UpdateItemModal';
import { ChangeItemOwnershipModal } from './updateOwnershipModal/ChangeItemStatusModal';

interface ItemDetailsMenuProps {
    item: ItemDto;
    onCreateRepairForItem(): void;
}

enum ModalType {
    UpdateDetails = 'UpdateDetails',
    ChangeStatus = 'ChangeStatus',
    ChangeOwnership = 'ChangeOwnarship',
    AddCode = 'AddCode',
    SentToRepairs = 'SentToRepairs',
    ArrivedFromRepairs = 'ArrivedFromRepairs',
    CreateAssignment = 'CreateAssignment',
    ConfirmRemoveOrganization = 'ConfirmRemoveOrganization',
}

export const ItemDetailsMenu: FC<ItemDetailsMenuProps> = ({ item, onCreateRepairForItem }) => {
    const { hasPermission } = usePermission();
    const { unassignSubContract } = useUnassignSubContract();

    const [menuLink] = useAuditLogMenuOption(AuditableTable.Item, item.id);

    const [modal, setModal] = useState<ModalType>();
    const closeModal = () => setModal(undefined);

    const redistributableIn = itemRedistributableIn(item);
    const isConsumableItem = isCategoryConsumable(item.product.category);

    return (
        <>
            <MoreMenu
                marginLeft={12}
                items={[
                    {
                        icon: SvgIcon.Archive,
                        action: () => setModal(ModalType.ChangeStatus),
                        label: 'Update status',
                        visible: hasPermission(Permission.AdminItemsChangeStatus),
                    },
                    {
                        icon: SvgIcon.Edit,
                        action: () => setModal(ModalType.UpdateDetails),
                        label: 'Update details',
                        visible: hasPermission(Permission.AdminItemsUpdate),
                    },
                    {
                        icon: SvgIcon.Edit,
                        action: () => setModal(ModalType.ChangeOwnership),
                        label: 'Change ownersship',
                        visible: hasPermission(Permission.AdminItemsChangeOwnership),
                    },
                    {
                        icon: SvgIcon.Add,
                        action: () => setModal(ModalType.AddCode),
                        label: 'Assign code',
                        visible:
                            hasPermission(Permission.AdminItemsCreateCode) &&
                            !writtenOffStatus.includes(item.status),
                    },
                    {
                        icon: SvgIcon.Replace,
                        action: () => setModal(ModalType.CreateAssignment),
                        label: 'Redistribute',
                        visible:
                            hasPermission(Permission.AdminItemsUpdate) &&
                            !isConsumableItem &&
                            item.status === ItemStatus.Active &&
                            !!item.redistributableInContractId,
                    },
                    {
                        icon: SvgIcon.Tool,
                        action: onCreateRepairForItem,
                        label: 'Repair',
                        visible:
                            hasPermission(Permission.AdminRepairsCreate) &&
                            isValidRepairStatus(item.status) &&
                            isValidRepairLocation(item.location.location) &&
                            !isConsumableItem,
                    },
                    {
                        icon: SvgIcon.Building,
                        action: () => setModal(ModalType.ConfirmRemoveOrganization),
                        label: 'Unlink organization',
                        visible:
                            hasPermission(Permission.AdminItemsUpdate) &&
                            item.contractDetails !== null &&
                            !isConsumableItem &&
                            item.ownership.type === Ownership.Hofy,
                    },

                    menuLink,
                ]}
            />

            <Modals>
                {modal === ModalType.ChangeStatus && (
                    <ChangeItemStatusModal item={item} onCancel={closeModal} />
                )}
                {modal === ModalType.ChangeOwnership && (
                    <ChangeItemOwnershipModal item={item} onCancel={closeModal} />
                )}
                {modal === ModalType.UpdateDetails && <UpdateItemModal item={item} onClose={closeModal} />}
                {modal === ModalType.CreateAssignment && redistributableIn && (
                    <CreateAssignmentModal
                        itemId={item.id}
                        organizationId={item.contractDetails?.organization?.id!}
                        redistributableIn={redistributableIn}
                        onClose={closeModal}
                    />
                )}
                {modal === ModalType.AddCode && (
                    <UpdateItemCodeModal
                        itemId={item.id}
                        onCancel={closeModal}
                        onSuccess={closeModal}
                        product={item.product}
                        variant={item.variant}
                    />
                )}
                {modal === ModalType.ConfirmRemoveOrganization && (
                    <ConfirmModal
                        keyPrefix='item-remove-organization-modal'
                        onConfirm={() => unassignSubContract(item.id)}
                        onClose={closeModal}
                        action='destructive'
                    >
                        <Paragraph3 marginTop={10} color={Color.ContentPrimary}>
                            Organization: <Span bold>{item.contractDetails?.organization?.name}</Span>
                        </Paragraph3>
                    </ConfirmModal>
                )}
            </Modals>
        </>
    );
};

const itemRedistributableIn = (item: ItemDto) => {
    if (
        item.contractDetails?.type === ContractType.Rental &&
        activeOrPendingRentalContractStatuses.includes(item.contractDetails.rentalContract.status)
    ) {
        return item.contractDetails.rentalContract.country;
    }
    return item.location.warehouse?.country;
};
