import React, { FC, memo } from 'react';

import { useOrganizationContractSubscriptions, useTrSubscriptionTerm } from '@hofy/api-admin';
import { useTrPaymentSchema } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfinityScrollTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface SubscriptionTableComponentProps {
    organizationId: UUID;
    organizationContractId: UUID;
}

const SubscriptionTableComponent: FC<SubscriptionTableComponentProps> = ({
    organizationId,
    organizationContractId,
}) => {
    const { formatPrice } = usePrice();
    const trPaymentSchema = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();

    const { subscriptions, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useOrganizationContractSubscriptions(organizationId, organizationContractId);
    const subscriptionsScrollDetails = {
        hasMore: hasNextPage,
        isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    };

    return (
        <InfinityScrollTable
            data={subscriptions}
            toKey={v => v.id}
            infinityScroll={subscriptionsScrollDetails}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No subscriptions' />}
            columns={[
                {
                    id: 'billingFrequency',
                    header: 'Billing frequency',
                    width: 300,
                    renderer: s => trPaymentSchema(s.billingFrequency),
                },
                {
                    id: 'term',
                    header: 'Term',
                    width: 200,
                    renderer: s => trTerm(s.term),
                },
                {
                    id: 'price',
                    header: 'Monthly price',
                    width: 200,
                    renderer: s => formatPrice(s.price),
                },
                {
                    id: 'quantity',
                    header: 'Quantity',
                    width: 200,
                    renderer: s => s.quantity,
                },
                {
                    id: 'startOn',
                    header: 'Start on',
                    width: 200,
                    renderer: s => formatDate(s.startOn),
                },
                {
                    id: 'endOn',
                    header: 'End on',
                    width: 200,
                    renderer: s => formatDate(s.endOn),
                },
            ]}
        />
    );
};

export const SubscriptionTable = memo(SubscriptionTableComponent);
