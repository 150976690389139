import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UUID } from '@hofy/global';

import { SubscriptionDto } from '../../subscription/service/types/SubscriptionDto';
import { organizationContractService } from '../service/organizationContractService';
import { organizationContractCacheKey } from './cacheKey';

export const useOrganizationContractSubscriptions = (
    organizationId: UUID,
    organizationContractId: UUID,
    pageSize = 50,
) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [organizationContractCacheKey, organizationContractId, 'subscriptions'],
        queryFn: param =>
            organizationContractService.listOrganizationContractSubscriptions(
                organizationId,
                organizationContractId,
                {
                    page: param.pageParam,
                    pageSize,
                },
            ),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const subscriptions: SubscriptionDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        subscriptions,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
