import React, { FC } from 'react';

import { BulkStorageOrderItemDto } from '@hofy/api-admin';
import { formatVariant, useTrProductCategory } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { BaseTable, BaseTableColumnConfig, BaseTableHeader, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { ProductItem } from '../../../../components/domain/products/ProductItem';

interface BulkStorageOrderItemsTableProps {
    orderItems: BulkStorageOrderItemDto[];
}

export const BulkStorageOrderItemsTable: FC<BulkStorageOrderItemsTableProps> = ({ orderItems }) => {
    const trProductCategory = useTrProductCategory();

    return (
        <BaseTable
            data={orderItems}
            toKey={bulkStorageOrderItem => bulkStorageOrderItem.id}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            headerRenderer={BulkStorageOrderItemsTableHeader}
            columns={[
                {
                    id: 'product',
                    header: 'Product',
                    width: 400,
                    renderer: orderItem => (
                        <ProductItem
                            image={orderItem.variant.image}
                            label={orderItem.product.name}
                            subLabel={formatVariant(orderItem.variant)}
                        />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    renderer: orderItem => trProductCategory(orderItem.product.category),
                },
                {
                    id: 'sku',
                    header: 'SKU',
                    renderer: orderItem => orderItem.variant.sku,
                },
                {
                    id: 'reserved',
                    header: 'Pending Assignments',
                    renderer: orderItem => (
                        <Paragraph3 gap={8}>
                            {getReceivedItemsIcon(orderItem.reservedQuantity, orderItem.assignedQuantity)}
                            {orderItem.reservedQuantity - orderItem.assignedQuantity}
                        </Paragraph3>
                    ),
                },
            ]}
        />
    );
};

const BulkStorageOrderItemsTableHeader = (columns: BaseTableColumnConfig<BulkStorageOrderItemDto>[]) => (
    <BaseTableHeader paddingHorizontal={40} columns={columns} bgColor={Color.BackgroundSurfaceNeutral} />
);

const getReceivedItemsIcon = (receivedQuantity: number, expectedQuantity: number) => {
    if (receivedQuantity === expectedQuantity) {
        return <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />;
    }

    if (receivedQuantity < expectedQuantity) {
        return <Icon svg={SvgIcon.ArrowDown} color={Color.ContentNegative} />;
    }

    return null;
};
