export const roleI18n = {
    'role.manager': 'Manager',
    'role.read-only-manager': 'Read only manager',
    'role.access-manager': 'Access manager',
    'role.financial-manager': 'Financial manager',
    'role.pii-manager': 'PII manager',
    'role.team-member': 'Team member',
    'role.it-manager': 'IT manager',

    'role.organization-teleporter-admin': 'Organization teleporter',
    'role.read-only-organization-teleporter-admin': 'Read only organization teleporter',
    'role.financial-admin': 'Financial admin',
    'role.finance-director-admin': 'Finance director admin',
    'role.finance-engineer-admin': 'Finance engineer admin',
    'role.fulfillment-admin': 'Fulfillment admin',
    'role.super-admin': 'Super admin',
    'role.read-only-admin': 'Read only admin',
    'role.permission-admin': 'Permission admin',
    'role.user-admin': 'User admin',
    'role.sales-admin': 'Sales',
    'role.support-admin': 'Support',
    'role.organization-admin': 'Organization admin',
    'role.catalogue-admin': 'Catalogue admin',
    'role.it-admin': 'IT admin',
    'role.support-plus-admin': 'Support plus',
    'role.success-admin': 'Success',
    'role.financial-approve-admin': 'Financial approval admin',
    'role.procurement-approve-admin': 'Procurement approval admin',
    'role.procurement-admin': 'Procurement admin',
    'role.approve-admin': 'Approve admin',
    'role.early-tester-admin': 'Early tester admin',
};
