import React, { FC } from 'react';

import { useBulkStorageOrders } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { ExportButton, Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { ActiveFilters, ActiveFiltersLayout, Button, FilterSearchInput, PageHeader, SvgIcon } from '@hofy/ui';

import { useBulkStorageOrdersFilters } from '../../../store/bulkStorageOrders/useBulkStorageOrderFilters';
import { useBulkStorageOrdersExport } from '../../../store/bulkStorageOrders/useBulkStorageOrdersExport';
import { InventoryTab } from '../../../store/inventory/types/InventoryTab';
import { InventoryTabs } from '../InventoryTabs';
import { BulkStorageOrderTable } from './components/BulkStorageOrderTable';

interface BulkStorageOrdersPageProps {
    onAssignOrder(id: UUID): void;
    onOrderDetails(id: UUID): void;
    onNewOrder(): void;
}

export const BulkStorageOrdersPage: FC<BulkStorageOrdersPageProps> = ({
    onNewOrder,
    onAssignOrder,
    onOrderDetails,
}) => {
    const { filters, filterValues, filterCount, clearFilters } = useBulkStorageOrdersFilters();
    const { bulkStorageOrders, bulkStorageOrdersIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useBulkStorageOrders(filterValues);

    const { hasPermission } = usePermission();
    const { download, isDownloading } = useBulkStorageOrdersExport(filterValues);

    return (
        <Page pageId='bulk-storage-orders'>
            <PageHeader
                title='Bulk storage orders'
                rightSlot={
                    <>
                        <ExportButton onClick={download} disabled={isDownloading} />
                        <FilterSearchInput filter={filters.search} autoFocus />
                        <Button
                            leftIcon={SvgIcon.Add}
                            label='New bulk storage order'
                            disabled={!hasPermission(Permission.AdminBulkStorageOrdersUpdate)}
                            onClick={onNewOrder}
                        />
                    </>
                }
                tabsSlot={<InventoryTabs active={InventoryTab.BulkStorageOrders} />}
            />

            <ActiveFiltersLayout show={filterCount > 0}>
                <ActiveFilters filters={filters} onClearAll={clearFilters} />
            </ActiveFiltersLayout>

            <BulkStorageOrderTable
                orders={bulkStorageOrders}
                onAssignOrder={onAssignOrder}
                onOrderDetails={onOrderDetails}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: bulkStorageOrdersIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
                filters={filters}
            />
        </Page>
    );
};
