import { intersection } from 'lodash-es';
import React, { FC, useMemo } from 'react';

import { ExtendedBillingEntityDto } from '@hofy/api-admin';
import { Country, UUID } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import {
    ActiveFilters,
    ActiveFiltersLayout,
    BaseTable,
    Box,
    CountryFlag,
    Paragraph3,
    Placeholder,
    SvgIllustration,
    Tooltip,
} from '@hofy/ui';

import { FilterCountryHeaderCell } from '../../../../components/domain/country/FilterCountryHeaderCell';
import { useBillingEntityFilter } from '../../../../store/organizations/useBillingEntityFilters';
import {
    BillingEntityTableListColumn,
    billingEntityTableListColumns,
} from '../../../invoicingPage/billingEntities/components/BillingEntitiesInfinityList';

interface BillingEntitiesTableListProps {
    entries: ExtendedBillingEntityDto[];
    onOpenBillingEntity(id: UUID): void;
}

export const BillingEntitiesTable: FC<BillingEntitiesTableListProps> = ({ entries, onOpenBillingEntity }) => {
    const { filters, filterCount, clearFilters } = useBillingEntityFilter();

    const billingEntityOrganizationTableColumns = useMemo(() => {
        const columns = billingEntityTableListColumns.filter(
            c => BillingEntityTableListColumn.organization !== c.id,
        );
        return [
            ...columns,
            {
                id: BillingEntityTableListColumn.countries,
                header: <FilterCountryHeaderCell label={'Invoice countries'} filter={filters.countries} />,
                renderer: (b: ExtendedBillingEntityDto) => (
                    <CountrySection countries={b.effectiveCountries} />
                ),
            },
        ];
    }, [filters]);

    const data = useMemo(
        () =>
            entries.filter(e =>
                filters.countries.value.length
                    ? !!intersection(e.effectiveCountries, filters.countries.value).length
                    : true,
            ),
        [entries, filters.countries],
    );

    return (
        <>
            <ActiveFiltersLayout show={filterCount > 0}>
                <ActiveFilters filters={filters} onClearAll={clearFilters} />
            </ActiveFiltersLayout>
            <BaseTable
                data={data}
                toKey={entry => entry.id}
                onRowClick={b => onOpenBillingEntity(b.id)}
                emptyContent={
                    <Placeholder illustration={SvgIllustration.FinanceSearch} title='No billing entities' />
                }
                minWidth={1200}
                columns={billingEntityOrganizationTableColumns}
            />
        </>
    );
};

interface CountrySectionProps {
    countries: Country[];
}

export const CountrySection: FC<CountrySectionProps> = ({ countries }) => {
    const trCountry = useTrCountry();
    if (countries.length == 0) {
        return null;
    }
    const displayCountries = countries.length > 3 ? countries.slice(0, 10) : countries;
    const diff = countries.length - displayCountries.length;
    return (
        <Box gap={4}>
            {displayCountries.map(v => (
                <Tooltip title={trCountry(v)}>
                    {' '}
                    <CountryFlag country={v} />
                </Tooltip>
            ))}
            {diff > 0 && <Paragraph3 color={Color.ContentSecondary}>{`+${diff} more...`}</Paragraph3>}
        </Box>
    );
};
