import React, { FC, useMemo } from 'react';

import { BulkStorageOrderItemDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, Icon, InnerCard, Paragraph2, Paragraph3, SvgIcon } from '@hofy/ui';

import { BulkStorageOrderProductInfo } from './BulkStorageOrderProductBox';

interface BulkStorageOrderItemBoxProps {
    item: BulkStorageOrderItemDto;
    setBulkStorageOrderItem(bulkStorageOrderItem: BulkStorageOrderItemDto): void;
}

export const BulkStorageOrderItemBox: FC<BulkStorageOrderItemBoxProps> = ({
    item,
    setBulkStorageOrderItem,
}) => {
    const isLineAllowedToReceive = useMemo(() => {
        return item && item.reservedQuantity !== item.assignedQuantity;
    }, [item]);

    return (
        <InnerCard pointer={isLineAllowedToReceive}>
            <Box
                fullWidth
                row
                justify='space-between'
                onClick={isLineAllowedToReceive ? () => setBulkStorageOrderItem(item) : () => {}}
            >
                <Box row gap={12}>
                    <BulkStorageOrderProductInfo item={item} />
                </Box>
                <Box row gap={24}>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Expected quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>{item.reservedQuantity}</Paragraph2>
                    </Box>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Received quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>
                            {!isLineAllowedToReceive ? (
                                <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />
                            ) : (
                                item.assignedQuantity || '--'
                            )}
                        </Paragraph2>
                    </Box>
                    <Icon svg={SvgIcon.ChevronRight} />
                </Box>
            </Box>
        </InnerCard>
    );
};
