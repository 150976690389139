import React, { FC } from 'react';

import { AuditLogDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import {
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { AuditLogOperationStatusChip } from '../../components/domain/auditLogs/AuditLogOperationTypeChip';
import { ActorLabel } from './components/ActorLabel';

interface AuditLogsTableProps {
    logs: AuditLogDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenLogs(auditId: UUID): void;
}

export const AuditLogsTable: FC<AuditLogsTableProps> = ({ logs, infinityScroll, onOpenLogs }) => {
    return (
        <InfinityScrollTable
            data={logs}
            toKey={log => log.id}
            onRowClick={log => onOpenLogs(log.id)}
            infinityScroll={infinityScroll}
            emptyContent={<Placeholder illustration={SvgIllustration.NotFound} title='No logs' />}
            minWidth={1300}
            columns={[
                {
                    id: 'operationType',
                    header: 'Operation type',
                    width: 150,
                    flexGrow: 0,
                    renderer: log => <AuditLogOperationStatusChip operationType={log.operationType} />,
                },
                {
                    id: 'auditTableName',
                    header: 'Table',
                    renderer: log =>
                        `${log.relation.tableName} #${log.relation.tableUuid || log.relation.tableId}`,
                    flexGrow: 1,
                },
                {
                    id: 'method',
                    header: 'Method',
                    renderer: log => log.method || '--',
                    flexGrow: 2,
                },
                {
                    id: 'updatedBy',
                    header: 'Actor',
                    flexGrow: 1,
                    renderer: log => (
                        <Paragraph3>
                            <ActorLabel actor={log.actor} />
                        </Paragraph3>
                    ),
                },
                {
                    id: 'transaction',
                    header: 'Transaction',
                    width: 120,
                    flexGrow: 0,
                    renderer: log => {
                        if (!log.transactionId) {
                            return '--';
                        }
                        return log.transactionId.substring(0, 8);
                    },
                },
                {
                    id: 'createdAt',
                    header: 'Created at',
                    renderer: log => formatDateTime(log.createdAt),
                },
            ]}
        />
    );
};
