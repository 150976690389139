import React, { FC } from 'react';

import { BulkStorageOrderStatus, bulkStorageOrderStatusColors } from '@hofy/api-shared';
import { DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, OuterBoxProps, Tooltip } from '@hofy/ui';

import { useTrBulkStorageOrderStatus } from '../../../store/bulkStorageOrders/useTrBulkStorageOrderStatus';

interface BulkStorageOrderStatusChipProps extends OuterBoxProps {
    status: BulkStorageOrderStatus;
    dateTime?: DateTimeString;
}

export const BulkStorageOrderStatusChip: FC<BulkStorageOrderStatusChipProps> = ({
    status,
    dateTime: date,
    ...boxProps
}) => {
    const trBulkStorageOrderStatus = useTrBulkStorageOrderStatus();

    if (!status) {
        return null;
    }

    const color = bulkStorageOrderStatusColors[status];

    return (
        <Tooltip body={date && formatDate(date)}>
            <Badge color={color} label={trBulkStorageOrderStatus(status)} {...boxProps} />
        </Tooltip>
    );
};
