import React, { FC } from 'react';

import { ArrayField, Box, FormContainer, FormInput, UseForm, UseMultipartFormState } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { FormHofyWarehouseSelect } from '../../../../components/domain/warehouses/FormHofyWarehouseSelect';
import {
    BulkStorageOnlyOrderFormData,
    BulkStorageOrderFormItemData,
    BulkStorageOrderMultipartForm,
    WizardSteps,
} from '../../../../store/bulkStorageOrders/types/BulkStorageOrderFormData';
import { BulkStorageItemsForm } from './BulkStorageItemsForm';

interface CreateBulkStorageOrderFormProps {
    form: UseMultipartFormState<BulkStorageOrderMultipartForm>;
    items: ArrayField<BulkStorageOrderFormItemData>;
    step: WizardSteps;
}

export const CreateBulkStorageOrderForm: FC<CreateBulkStorageOrderFormProps> = ({ form, items, step }) => {
    const content = () => {
        switch (step) {
            case WizardSteps.Order:
                return <BasicDataForm form={form.forms[WizardSteps.Order]} />;
            case WizardSteps.Items:
                return (
                    <BulkStorageItemsForm
                        items={items}
                        warehouseId={form.forms[WizardSteps.Order].values.toWarehouseId!}
                        orgId={form.forms[WizardSteps.Order].values.organizationId!}
                    />
                );
        }
    };

    return <Box paddingVertical={10}>{content()}</Box>;
};

interface BasicDataFormProps {
    form: UseForm<BulkStorageOnlyOrderFormData>;
}

const BasicDataForm: FC<BasicDataFormProps> = ({ form }) => {
    return (
        <FormContainer marginVertical={10}>
            <FormOrganizationSelect
                label='Organization'
                api={form.fields.organizationId}
                placeholder='Select an organisation'
                isRequired
                nullable
            />
            <FormHofyWarehouseSelect
                api={form.fields.toWarehouseId}
                warehouseFilter={{ byodSupported: true }}
                isRequired
                nullable
                label='Warehouse'
            />
            <FormInput
                label='Order reference'
                api={form.fields.orderReference}
                placeholder='Enter an order reference'
                nullable
            />
        </FormContainer>
    );
};
