import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { BulkStorageOrdersFilter } from '../store/BulkStorageOrdersFilter';
import { AssignItemsToBulkStorageOrderPayload } from './types/AssignItemsToBulkStorageOrderPayload';
import { BulkStorageOrderDto } from './types/BulkStorageOrderDto';
import { BulkStorageOrderPayload } from './types/BulkStorageOrderPayload';

class BulkStorageOrderService {
    public createBulkStorageOrder = async (payload: BulkStorageOrderPayload): Promise<UUID> => {
        return await restClient
            .postJson<{
                id: UUID;
            }>('/api/admin/bulk-storage-orders', payload)
            .then(r => r.id);
    };

    public getBulkStorageOrder = (id: UUID): Promise<BulkStorageOrderDto> => {
        return restClient.getJson<BulkStorageOrderDto>(`/api/admin/bulk-storage-orders/${id}`);
    };

    public listBulkStorageOrders = (
        filters: BulkStorageOrdersFilter,
        page: PageRequest,
    ): Promise<PageResponse<BulkStorageOrderDto>> => {
        return restClient.getJson<PageResponse<BulkStorageOrderDto>>(
            stringifyUrl({
                url: `/api/admin/bulk-storage-orders`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public exportBulkStorageOrders = async (filters: BulkStorageOrdersFilter): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: `/api/admin/bulk-storage-orders/report`,
                    query: {
                        ...filters,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public cancelBulkStorageOrder = async (id: UUID): Promise<void> => {
        await restClient.post(`/api/admin/bulk-storage-orders/${id}/cancel`, undefined);
    };

    public acceptBulkStorageOrder = async (id: UUID): Promise<void> => {
        await restClient.post(`/api/admin/bulk-storage-orders/${id}/accept`, undefined);
    };

    public downloadBulkStorageOrderDocument = async (
        bulkStorageOrderId: UUID,
        fileId: UUID,
    ): Promise<void> => {
        await restClient
            .downloadFile(`/api/admin/bulk-storage-orders/${bulkStorageOrderId}/documents/${fileId}`)
            .then(downloadFileFromResponse);
    };

    public assignBulkStorageOrderItem = async (
        bulkStorageOrderItemId: UUID,
        payload: AssignItemsToBulkStorageOrderPayload,
    ): Promise<void> => {
        await restClient.post(
            `/api/admin/bulk-storage-order-items/${bulkStorageOrderItemId}/assign-items`,
            payload,
        );
    };
}

export const bulkStorageOrderService = new BulkStorageOrderService();
